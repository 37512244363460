import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/skinJournal.css";
import Footer from "components/Footer";

const SkinJournal = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="skinJournal-container">
        <div className="skinJournal-title">{t("coming_soon")}</div>
      </div>
      <Footer />
    </>
  );
};

export default SkinJournal;
