import React, { useState, useContext, useEffect } from "react";
import "../../styles/verification.css";
import Lock from "../../assets/lock-screen.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import AuthContext from "AuthContext";
import PinInputContainer from "../../components/PinInput";
import User from "User";
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";

export default function Page() {
  const location = useLocation();
  const { phoneNumber } = location.state || {};
  const [otp, setOtp] = useState(""); // State to store the OTP input
  const [verificationError, setVerificationError] = useState(null);
  const [loading, setLoading] = useState(false); // State to track loading state
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext); // Access context for update

  async function handleSubmit() {
    try {
      setLoading(true);

      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/verifyOtp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: phoneNumber,
            otp: otp,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.message === "Invalid OTP") {
          setOtp("");
          setVerificationError("Invalid OTP. Please enter the correct code.");
        } else if (responseData.message === "Login successfully") {
          const user = new User(responseData.user);
          localStorage.setItem("user", JSON.stringify(user));
          setIsAuthenticated(true);

          if (location.state && location.state.fromResetPassword) {
            await fetch(
              "https://dashboard.natindamiddleeast.com/api/resetPassword",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  phone: phoneNumber,
                }),
              }
            );
          }

          if (location.state && location.state.fromQuiz) {
            navigate(location.state.result);
          } else {
            navigate("/");
          }
        }
      } else {
        console.error("Verification failed:", response.statusText);
      }
    } catch (error) {
      console.error("Verification failed:", error);
    } finally {
      setLoading(false); // Reset loading state after the request completes
    }
  }

  const handleResendCode = async () => {
    try {
      setLoading(true); // Set loading state to true when starting the request
      const response = await axios.post(
        "https://dashboard.natindamiddleeast.com/api/resendOTP",
        {
          phone: phoneNumber,
        }
      );
      // Clear the error message when OTP is resent successfully
      setVerificationError(null);
    } catch (error) {
      console.error("Resend Error:", error.response.data);
      // Handle error
    } finally {
      setLoading(false); // Reset loading state after the request completes
    }
  };

  return (
    <main>
      <div className="verification-container">
        <div className="confirm-text">Confirmation</div>
        <div>
          <img src={Lock} alt="lock" className="bottom-image" />
        </div>
        <div className="hint-text">
          Enter the 4-digit code sent to you <br />
          {phoneNumber && `at ${phoneNumber}`}
        </div>
        <PinInputContainer value={otp} onChange={setOtp} className="pinCode" />{" "}
        {/* Pass OTP input and update function */}
        {verificationError && (
          <div className="error-message">{verificationError}</div>
        )}
        <button className="submit" type="submit" onClick={handleSubmit}>
          NEXT
        </button>
        <button className="resend" onClick={handleResendCode}>
          Resend Code?
        </button>
      </div>
      {loading && ( // Render loading indicator if loading state is true
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      )}
    </main>
  );
}
