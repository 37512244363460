import ErrorIcon from "./Error";
import Valid from "./Valid";


export default function Input(props) {
  return (
 
    <div className="input-container">
        <label htmlFor={props.name}>{props.label}</label>
        <div
        className={`input-field ${
            !props.isValid && props.isTouched
            ? "invalid"
            : `${props.isValid && props.isTouched ? "valid" : ""}`
        }`}
        >
        
        
        <div className="input-row">
        <input
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.onChange(e)}
        />
        <div className="input__icon">
            {props.isTouched && !props.isValid && <ErrorIcon />}
            {props.isTouched && props.isValid && <Valid />}
        </div>
        </div>

        </div>
        <span className="input-error">{`${
            !props.isValid && props.isTouched ? props.errorMsg : ""
        }`}</span>
    </div>
  
  );
}
