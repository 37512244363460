import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import "../styles/sign-up.css";

const generateYears = (startYear, endYear) => {
  const years = [];
  for (let year = endYear; year >= startYear; year--) {
    years.push({ value: year, label: year });
  }
  return years;
};

const generateMonths = (t) => {
  const months = [
    { value: 1, label: t("January") }, // January
    { value: 2, label: t("February") }, // February
    { value: 3, label: t("March") }, // March
    { value: 4, label: t("April") }, // April
    { value: 5, label: t("May") }, // May
    { value: 6, label: t("June") }, // June
    { value: 7, label: t("July") }, // July
    { value: 8, label: t("August") }, // August
    { value: 9, label: t("September") }, // September
    { value: 10, label: t("October") }, // October
    { value: 11, label: t("November") }, // November
    { value: 12, label: t("December") }, // December
  ];
  return months;
};

const generateDays = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  const days = [];
  for (let day = 1; day <= daysInMonth; day++) {
    days.push({ value: day, label: day });
  }
  return days;
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    paddingRight: 0,
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#fef3ef", // Change to your desired background color
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333", // Change to your desired text color
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0", // Remove margin at the top
    backgroundColor: "#f0f0f0", // Change to your desired dropdown menu background color
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#d3d3d3" : "#f0f0f0", // Change to your desired option background color
    color: "#333", // Change to your desired option text color
  }),
  indicatorSeparator: (provided) => ({
    display: "none", // Remove the separator line
  }),
};

const DatePickerComponent = ({
  selectedYear,
  selectedMonth,
  selectedDay,
  setSelectedYear,
  setSelectedMonth,
  setSelectedDay,
}) => {
  const { t } = useTranslation(); // Using translation hook
  const currentYear = new Date().getFullYear();
  const years = generateYears(1900, currentYear);
  const months = generateMonths(t);
  const days =
    selectedYear && selectedMonth
      ? generateDays(selectedYear.value, selectedMonth.value)
      : [];

  return (
    <div className="dropdown-container">
      <Select
        className="dropdown picker"
        placeholder={t("YYYY")} // Translated placeholder for year
        value={selectedYear}
        onChange={setSelectedYear}
        options={years}
        styles={customStyles}
      />
      <Select
        className="dropdown picker"
        placeholder={t("MM")} // Translated placeholder for month
        value={selectedMonth}
        onChange={setSelectedMonth}
        options={months}
        styles={customStyles}
      />
      <Select
        className="dropdown picker"
        placeholder={t("DD")} // Translated placeholder for day
        value={selectedDay}
        onChange={setSelectedDay}
        options={days}
        styles={customStyles}
      />
    </div>
  );
};

export default DatePickerComponent;
