import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import WriteReview from 'components/WriteReview';
import Footer from 'components/Footer';

const New = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state: { productTheme, id: productId } } = location; // Destructure the state object
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [productId, productTheme]);

  if (loading) {
    return <div  style={{ height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>Loading...</div>
    ;
  }

  return (
    <main style={{ paddingTop: '115px'}}>
      {/* Your component content */}
      <WriteReview  ProductColor={productTheme} ProductId={productId} />
      <Footer productTheme={productTheme} />
      
    </main>
  );
};

export default New;
