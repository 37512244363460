import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import HeartIcon from "./HeartIcon";
import { Pagination } from "swiper/modules";
import ProductImages from "ProductImages/productImages";
import { Link } from "react-router-dom";
import CartContext from "CartContext";
import { getGlobalFlag } from "../components/flags";
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

export default function CardSwiper({ products }) {
  const isMenuOpen = getGlobalFlag();
  const { addToCart } = useContext(CartContext);
  const [isLoading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar"; // Detect if the language is Arabic

  const convertToArabicNumerals = (number) => {
    if (number == null) return "";
    const englishToArabicMap = {
      0: "٠",
      1: "١",
      2: "٢",
      3: "٣",
      4: "٤",
      5: "٥",
      6: "٦",
      7: "٧",
      8: "٨",
      9: "٩",
    };

    return number
      .toString()
      .split("")
      .map((char) => englishToArabicMap[char] || char)
      .join("");
  };
  // Hardcoded 304 in Arabic and English
  const displayHardCoddedPrice = isArabic ? "٣٠٤" : "304";

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="best-selling-swiper-container">
      {isLoading ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : (
        <>
          {!isMenuOpen && (
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              pagination={true}
              modules={[Pagination]}
            >
              {products.map((product, index) => (
                <SwiperSlide key={index}>
                  <div className="test-product-div">
                    <Link
                      to={`/product/${encodeURIComponent(
                        product.name_en.replace(/\s+/g, "-").toLowerCase()
                      )}/${product.id}`}
                      className="product-image-div"
                    >
                      {product.status === "Sale" &&
                        product.discounted_price && (
                          <div className="sale-badge">Sale</div>
                        )}

                      {product.status === "Sold out" && (
                        <div className="sale-badge">OUT OF STOCK</div>
                      )}
                      <ProductImages id={product.id} />
                    </Link>

                    <div className="hearts-container">
                      {Array.from(
                        { length: Math.round(product.average_rating) },
                        (_, index) => (
                          <HeartIcon
                            key={index}
                            color={product.theme}
                            width={16}
                            height={13}
                            alt={`Heart ${index + 1}`}
                          />
                        )
                      )}
                    </div>
                    <p
                      className={`product-name-home-mobile ${
                        isArabic ? "arabic" : ""
                      }`}
                    >
                      {" "}
                      <Link
                        to={`/product/${encodeURIComponent(
                          product.name_en.replace(/\s+/g, "-").toLowerCase()
                        )}/${product.id}`}
                      >
                        {isArabic ? product.name_ar : product.name_en}
                      </Link>
                    </p>
                    <button
                      className={`add-to-cart-button ${
                        isArabic ? "arabic" : ""
                      }`}
                      onClick={
                        product.status === "Sold out"
                          ? null
                          : (event) => handleAddToCart(product.id, event)
                      }
                      style={{
                        background:
                          product.status === "Sold out"
                            ? "#D3D3D3"
                            : product.theme,
                        cursor:
                          product.status === "Sold out"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      disabled={product.status === "Sold out"}
                    >
                      {product.status === "Sold out" ? (
                        <>OUT OF STOCK</>
                      ) : product.type === "bundle" ? (
                        <>
                          {t("add_to_cart")} -{" "}
                          <span
                            style={{
                              marginRight: "7px",
                            }}
                            className="strikethrough"
                          >
                            {displayHardCoddedPrice} {t("SAR")}
                          </span>
                          {isArabic
                            ? convertToArabicNumerals(product.price)
                            : product.price}{" "}
                          {t("SAR")}
                        </>
                      ) : product.status === "Sale" &&
                        product.discounted_price ? (
                        <>
                          {t("add_to_cart")} -{" "}
                          <span
                            style={{
                              marginRight: "7px",
                            }}
                            className="strikethrough"
                          >
                            {isArabic
                              ? convertToArabicNumerals(product.price)
                              : product.price}{" "}
                            {t("SAR")}
                          </span>
                          {isArabic
                            ? convertToArabicNumerals(product.discounted_price)
                            : product.discounted_price}{" "}
                          {t("SAR")}
                        </>
                      ) : (
                        <>
                          {t("add_to_cart")} -{" "}
                          {isArabic
                            ? convertToArabicNumerals(product.price)
                            : product.price}{" "}
                          {t("SAR")}
                        </>
                      )}
                    </button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </>
      )}
    </div>
  );
}
