import React, { useState } from 'react';
import PinInput from 'react-pin-input';

export default function PinInputContainer({ value, onChange }) {
    const [pin, setPin] = useState(value); // State to store the PIN input

    // Handle PIN input change
    const handlePinChange = (value) => {
        setPin(value); // Update PIN state
        onChange(value); // Call parent onChange function to update OTP state in the parent component
    };

    // Calculate the spacing between digits
    const digitSpacing = 8; // You can adjust this value as needed

    return (
        <div>
            <PinInput 
                length={4} 
                initialValue=""
                secretDelay={100} 
                onChange={(value, index) => handlePinChange(value)} // Call handlePinChange on input change
                type="numeric" 
                inputMode="number"
                style={{ padding: '10px' }}  
                inputStyle={{ 
                    borderColor: '#818181', 
                    borderRadius: '10px',
                    marginRight: digitSpacing, // Add right margin for spacing between digits
                }}
                inputFocusStyle={{ borderColor: 'black' }}
                onComplete={(value, index) => {
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
        </div>
    );
}
