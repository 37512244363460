import React, { useState, useEffect, useContext } from "react";
import "./products.css";
import Product from "components/Product";
import { useLocation } from "react-router-dom";
import Error from "./../../assets/error.png";
import Footer from "components/Footer";
import { SyncLoader } from "react-spinners";
import "../../fonts/fonts.css";
import { Link } from "react-router-dom";
import HeartIcon from "components/HeartIcon";
import CartContext from "CartContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const { addToCart } = useContext(CartContext);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);

    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("q");

    const apiUrl = `https://dashboard.natindamiddleeast.com/api/getallproducts${
      searchQuery ? `?q=${encodeURIComponent(searchQuery)}` : ""
    }`;

    axios
      .get(apiUrl)
      .then((response) => {
        setProducts(response.data); // Axios automatically parses JSON response
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.search]);

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      {isLoading ? (
        <div className="global-full-page">
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : error ? (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text">{t("OOPS")}</div>
        </div>
      ) : (
        <>
          <div className="products-web-view">
            <div className="product-container">
              <div className="shop-all">{t("shop_all")}</div>
              {products.length === 0 ? (
                <div className="global-full-page">{t("no_products")}</div>
              ) : (
                <div className="products-column">
                  {products.map((product, index) => (
                    <Product
                      key={index}
                      product={product}
                      setLoading={setLoading}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="products-mobile-view">
            <div className="shop-all">{t("shop_all")}</div>

            {products.length === 0 ? (
              <div className="global-full-page">{t("no_products")}</div>
            ) : (
              <div className="products-column-mobile">
                {products.map((product, index) => {
                  const averageRating =
                    product.avg_rating < 1 ? 3 : product.avg_rating;
                  const buttonColor = "#FDDCCD";
                  const heartColor = "#FCDBCC";
                  const thumbnailImage = product.medias.find(
                    (media) => media.tag === "thumbnail"
                  );
                  const route = `/product/${encodeURIComponent(
                    product.name_en.replace(/\s+/g, "-").toLowerCase()
                  )}/${product.id}`;

                  const saleFlag =
                    product.status === "Sale" && product.discounted_price
                      ? true
                      : false;
                  const soldOutFlag =
                    product.status === "Sold out" ? true : false;
                  const bundleFlag = product.type === "bundle" ? true : false;

                  return (
                    <div key={index} className="product-item-mobile">
                      <Link to={route}>
                        <div className="product-mobile">
                          {saleFlag && (
                            <div className="sale-badge">{t("Sale")}</div>
                          )}
                          {soldOutFlag && (
                            <div className="sale-badge">
                              {t("out_of_stock")}
                            </div>
                          )}
                          {bundleFlag && (
                            <div className="sale-badge">{t("sale_50")}</div>
                          )}

                          <img
                            src={
                              thumbnailImage
                                ? thumbnailImage.image_url
                                : product.medias[0].image_url
                            }
                            alt={product.tag}
                          />
                          {product.type === "standard" && (
                            <div
                              className="hearts-container"
                              style={{ marginBottom: "0px" }}
                            >
                              {Array.from(
                                { length: Math.round(averageRating) },
                                (_, index) => (
                                  <HeartIcon
                                    key={index}
                                    color={heartColor}
                                    width={22}
                                    height={17}
                                    alt={`Heart ${index + 1}`}
                                  />
                                )
                              )}
                            </div>
                          )}
                          <div className="products-name">
                            {" "}
                            {product[`name_${i18n.language.slice(-2)}`]}{" "}
                          </div>
                          <button
                            className="add-to-cart-product-mobile"
                            style={{
                              background: soldOutFlag
                                ? "rgba(196, 192, 192, 1)"
                                : buttonColor,
                              cursor: soldOutFlag ? "not-allowed" : "pointer",
                            }}
                            onClick={
                              soldOutFlag
                                ? null
                                : (event) => handleAddToCart(product.id, event)
                            }
                            disabled={soldOutFlag}
                          >
                            {soldOutFlag ? (
                              <>{t("out_of_stock")}</>
                            ) : product.type === "bundle" ? (
                              <>
                                {t("add_to_cart")} -{" "}
                                <span
                                  style={{
                                    marginRight: "7px",
                                  }}
                                  className="strikethrough"
                                >
                                  304 {t("SAR")}
                                </span>
                                {product.price} {t("SAR")}
                              </>
                            ) : product.status === "Sale" &&
                              product.discounted_price ? (
                              <>
                                {t("add_to_cart")} -{" "}
                                <span
                                  style={{
                                    marginRight: "7px",
                                  }}
                                  className="strikethrough"
                                >
                                  {product.price} {t("SAR")}
                                </span>
                                {product.discounted_price} {t("SAR")}
                              </>
                            ) : (
                              <>
                                {t("add_to_cart")} - {product.price} {t("SAR")}
                              </>
                            )}
                          </button>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
      <Footer />
    </main>
  );
};

export default Products;
