import React, { useState } from "react";
import AWS from "aws-sdk";

const UploadImageForm = () => {
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const handleImageChange = (event) => {
    setImages(event.target.files);
  };

  const uploadImages = async () => {
    if (images.length === 0) {
      alert("Please select at least one image.");
      return;
    }

    // Configure the AWS SDK
    AWS.config.update({
      accessKeyId: "AKIAQQ42ERCKMETU2E6I",
      secretAccessKey: "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",
      region: "eu-north-1",
    });

    const s3 = new AWS.S3();
    const bucketName = "patron-images";
    const productName = "product1";
    
    try {
      const uploadedUrls = [];

      for (let i = 0; i < images.length; i++) {
        const timestamp = Date.now();
        const imageName = `${productName}_${timestamp}_${images[i].name}`;
        const key = `products/${imageName}`;

        const params = {
          Bucket: bucketName,
          Key: key,
          Body: images[i],
          ACL: "public-read",
        };

        console.log(`Uploading image ${i + 1}...`);
        const data = await s3.upload(params).promise();
        console.log(`Image ${i + 1} upload successful:`, data);

        // Get the URL of the uploaded image
        const imageUrl = data.Location;
        console.log(`Image ${i + 1} URL:`, imageUrl);
        uploadedUrls.push(imageUrl);
      }

      setImageUrls(uploadedUrls);
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images. Please try again later.");
    }
  };

  return (
    <div style={{marginTop:'200px' }}>
    <input type="file" multiple onChange={handleImageChange} />
      <button onClick={uploadImages}>Upload Images</button>
      {imageUrls.length > 0 && (
        <div>
          <h2>Uploaded Images:</h2>
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              <p>Image URL {index + 1}: {imageUrl}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadImageForm;