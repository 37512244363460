import { Link } from "react-router-dom";
import HeartIcon from "./HeartIcon";
import AddToCart from "./addToCart";
import { useTranslation } from "react-i18next";

const Product = ({ product, setLoading }) => {
  const buttonColor = "#FDDCCD";
  const heartColor = "#FCDBCC";
  const averageRating = product.avg_rating < 1 ? 3 : product.avg_rating;
  const thumbnailImage = product.medias.find(
    (media) => media.tag === "thumbnail"
  );

  const saleFlag =
    product.status === "Sale" && product.discounted_price ? true : false;

  const soldOutFlag = product.status === "Sold out" ? true : false;
  const bundleFlag = product.type === "bundle" ? true : false;

  const { t, i18n } = useTranslation();

  return (
    <div className="product-item">
      <Link
        to={`/product/${encodeURIComponent(
          product.name_en.replace(/\s+/g, "-").toLowerCase()
        )}/${product.id}`}
      >
        <div className="product">
          {saleFlag && <div className="sale-badge">{t("Sale")}</div>}

          {soldOutFlag && <div className="sale-badge">{t("out_of_stock")}</div>}
          {bundleFlag && <div className="sale-badge">{t("sale_50")}</div>}

          <img
            src={
              thumbnailImage
                ? thumbnailImage.image_url
                : product.medias[0].image_url
            }
            alt={product.tag}
          />
          {product.type === "standard" && (
            <div className="hearts-container">
              {Array.from({ length: Math.round(averageRating) }, (_, index) => (
                <HeartIcon
                  key={index}
                  color={heartColor}
                  width={25}
                  height={22}
                  alt={`Heart ${index + 1}`}
                />
              ))}
            </div>
          )}
          <div className="products-name">
            {" "}
            {product[`name_${i18n.language.slice(-2)}`]}{" "}
          </div>
          <AddToCart
            buttonColor={buttonColor}
            product={product}
            setLoading={setLoading}
          />
        </div>
      </Link>
    </div>
  );
};

export default Product;
