import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Styles and fonts
import "./../dry_skin/dry_skin.css";
import "../../fonts/fonts.css";

// Assets
import Error from "./../../assets/error.png";
import Sensitive from "./../../assets/sensitive.png";
import Niacinamide from "./../../assets/Niacinamide.png";
import Aloe from "./../../assets/Aloe.png";
import Glycerin from "./../../assets/Glycerin.png";
import Centella from "./../../assets/Centella.png";
import greenTea from "./../../assets/greenTea.png";
import Cucumber from "./../../assets/Cucumber.png";

// Components
import Footer from "components/Footer";
import ProductSkin from "components/ProductSkin";

// External libraries
import { SyncLoader } from "react-spinners";

const SensitiveSkin = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);

    const apiUrl = `https://dashboard.natindamiddleeast.com/api/getProductsBySkinType`;
    const requestBody = {
      skin_type: "Sensitive Skin",
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setProducts(data.products);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const WhiteningBalmProduct = products.find((p) => p.id === 6);
  const careProduct = products.find((p) => p.id === 7);
  const uvCareProduct = products.find((p) => p.id === 9);
  const cleansingFoamProduct = products.find((p) => p.id === 10);
  const atticosProduct = products.find((p) => p.id === 11);

  return (
    <main>
      {isLoading ? (
        <div className="global-full-page">
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : error ? (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text">{t("OOPS")}</div>
        </div>
      ) : (
        <>
          <div className="dry-skin-web-view">
            <div className="dry-skin-container">
              <div className="dry-first-container">
                <div className="dry-first-container-text">
                  <div className="dry-first-container-text-header">
                    {t("skin_result")}
                  </div>
                  <div
                    className="dry-first-container-text-sub"
                    style={{ fontWeight: 700, marginBottom: "20px" }}
                  >
                    {t("itchy")}{" "}
                  </div>
                  <div className="dry-first-container-text-sub">
                    {t("sensitive_skin_result")}
                  </div>
                </div>
                <div className="dry-first-container-image">
                  <img src={Sensitive} alt="Sensitive " />
                </div>
              </div>
              <div className="dry-headline">{t("active_ingredients")}</div>
              <div className="dry-second-container">
                <div className="dry-second--single-container">
                  <img src={Glycerin} alt="Glycerin" />
                  <span style={{ fontWeight: 500 }}> {t("glycerin")}</span>
                  <span>{t("glycerin_result")}</span>
                </div>

                <div className="dry-second--single-container">
                  <img src={Aloe} alt="Aloe" />
                  <span style={{ fontWeight: 500 }}>{"aloe"}</span>
                  <span> {t("aloe_result")}</span>
                </div>

                <div className="dry-second--single-container">
                  <img src={Centella} alt="Aloe" />
                  <span style={{ fontWeight: 500 }}>{t("centella")}</span>
                  <span>{t("centella_result")}</span>
                </div>

                <div className="dry-second--single-container">
                  <img src={greenTea} alt="greenTea" />
                  <span style={{ fontWeight: 500 }}>{t("green_tea")}</span>
                  <span>{t("green_tea_result")}</span>
                </div>

                <div className="dry-second--single-container">
                  <img src={Cucumber} alt="greenTea" />
                  <span style={{ fontWeight: 500 }}>{t("cucumber")}</span>
                  <span>{t("cucumber_result")}</span>
                </div>

                <div className="dry-second--single-container">
                  <img src={Niacinamide} alt="Niacinamide" />
                  <span style={{ fontWeight: 500 }}>{t("niacinamide")}</span>
                  <span>{t("niacinamide_result")}</span>
                </div>
              </div>
              <div className="dry-headline" style={{ textAlign: "center" }}>
                {t("essential_products")}
              </div>

              <div className="dry-third-container">
                <div className="dry-headline-sub">{t("Beginning")}</div>
                <div className="dry-headline-sub-1">{t("good_morning")}</div>

                <ProductSkin
                  product={cleansingFoamProduct}
                  setLoading={setLoading}
                  ingredients={[t("centella"), t("green_tea"), t("aloe")]}
                  text={t("best_results")}
                />
                <div className="dry-headline-sub">{t("middle")}</div>
                <div className="dry-headline-sub-1">{t("middle_result")}</div>

                <ProductSkin
                  product={WhiteningBalmProduct}
                  setLoading={setLoading}
                  ingredients={[t("centella"), t("niacinamide")]}
                />

                <ProductSkin
                  product={atticosProduct}
                  setLoading={setLoading}
                  ingredients={[
                    t("glycerin"),
                    t("niacinamide"),
                    t("panthenol"),
                  ]}
                />

                <div className="dry-headline-sub">{t("end")}</div>
                <div className="dry-headline-sub-1">{t("end_result")}</div>

                <ProductSkin
                  product={careProduct}
                  setLoading={setLoading}
                  ingredients={[
                    t("centella"),
                    t("hyaluronic"),
                    t("niacinamide"),
                  ]}
                />

                <ProductSkin
                  product={uvCareProduct}
                  setLoading={setLoading}
                  ingredients={[t("centella"), t("hyaluronic")]}
                />
              </div>
            </div>
          </div>
          <div className="dry-skin-mobile-view">
            <div className="dry-skin-img-mob">
              <img src={Sensitive} alt="Sensitive " />
            </div>
            <div className="dry-skin-head-mob">{t("skin_result")}</div>
            <div className="dry-skin-head-sub">
              {t("itchy")} <br />
              <span style={{ fontWeight: 500, fontSize: "16px" }}>
                {t("sensitive_skin_result")}
              </span>
            </div>

            <div
              style={{ textAlign: "center", fontWeight: 400, fontSize: "22px" }}
              className="dry-skin-head-mob"
            >
              {t("active_ingredients")}
            </div>

            <div
              style={{ marginTop: "40px" }}
              className="dry-second-single-container-mob"
            >
              <div style={{ padding: "0px 20px" }}>
                <span style={{ fontWeight: 500 }}>
                  {t("glycerin")} <br />
                </span>
                <span>{t("glycerin_result")}</span>
              </div>
              <img src={Glycerin} alt="Glycerin" />
            </div>

            <div className="dry-second-single-container-mob">
              <div style={{ padding: "0px 20px" }}>
                <span style={{ fontWeight: 500 }}>
                  {t("aloe")} <br />
                </span>
                <span>{t("aloe_result")}</span>
              </div>
              <img src={Aloe} alt="Aloe" />
            </div>

            <div className="dry-second-single-container-mob">
              <div style={{ padding: "0px 20px" }}>
                <span style={{ fontWeight: 500 }}>
                  {t("centella")}
                  <br />
                </span>
                <span>{t("centella_result")}</span>
              </div>
              <img src={Centella} alt="Centella" />
            </div>

            <div className="dry-second-single-container-mob">
              <div style={{ padding: "0px 20px" }}>
                <span style={{ fontWeight: 500 }}>
                  {t("green_tea")}
                  <br />
                </span>
                <span>{t("green_tea_result")}</span>
              </div>
              <img src={greenTea} alt="greenTea" />
            </div>

            <div className="dry-second-single-container-mob">
              <div style={{ padding: "0px 20px" }}>
                <span style={{ fontWeight: 500 }}>
                  {t("cucumber")}
                  <br />
                </span>
                <span>{t("cucumber_result")}</span>
              </div>
              <img src={Cucumber} alt="Cucumber" />
            </div>

            <div className="dry-second-single-container-mob">
              <div style={{ padding: "0px 20px" }}>
                <span style={{ fontWeight: 500 }}>
                  {t("niacinamide")}
                  <br />
                </span>
                <span>{t("niacinamide_result")}</span>
              </div>
              <img src={Niacinamide} alt="Niacinamide" />
            </div>

            <div className="dry-headline" style={{ textAlign: "center" }}>
              {t("essential_products")}
            </div>

            <div className="dry-third-container-mob">
              <div className="dry-headline-sub">{t("Beginning")}</div>
              <div className="dry-headline-sub-1">{t("good_morning")}</div>

              <ProductSkin
                product={cleansingFoamProduct}
                setLoading={setLoading}
                ingredients={[t("centella"), t("green_tea"), t("aloe")]}
                text={t("best_results")}
              />
              <div className="dry-headline-sub">{t("middle")}</div>
              <div className="dry-headline-sub-1">{t("middle_result")}</div>

              <ProductSkin
                product={WhiteningBalmProduct}
                setLoading={setLoading}
                ingredients={[t("centella"), t("niacinamide")]}
              />

              <ProductSkin
                product={atticosProduct}
                setLoading={setLoading}
                ingredients={[t("glycerin"), t("niacinamide"), t("panthenol")]}
              />

              <div className="dry-headline-sub">{t("end")}</div>
              <div className="dry-headline-sub-1">{t("end_result")}</div>

              <ProductSkin
                product={careProduct}
                setLoading={setLoading}
                ingredients={[t("centella"), t("hyaluronic"), t("niacinamide")]}
              />

              <ProductSkin
                product={uvCareProduct}
                setLoading={setLoading}
                ingredients={[t("centella"), t("hyaluronic")]}
              />
            </div>
          </div>
        </>
      )}
      <Footer />
    </main>
  );
};

export default SensitiveSkin;
