import React, { useEffect, useState, useContext } from "react";
import "../../styles/cart.css";
import CounterBox from "components/CounterBox";
import BlackLine from "../../assets/Line 3.svg";
import { SyncLoader } from "react-spinners";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import Input from "scenes/signUp/Input";
import User from "User";
import Checkbox from "@mui/material/Checkbox";
import Select, { components } from "react-select";
import { Country, State } from "country-state-city";
import downArrow from "../../assets/chevron-down.svg";
import Button from "../signUp/Button";
import validateEmail from "scenes/signUp/utils";
import { useNavigate } from "react-router-dom";
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";

const Cart = () => {
  const [loading, setLoading] = useState(true);
  const [cartEmpty, setCartEmpty] = useState(false);
  const [products, setProducts] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [cart, setCart] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);
  const [receiveEmailsChecked, setReceiveEmailsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  // const [cityOptions, setCityOptions] = useState([]);
  const [saveAddress, setSaveAddress] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { fetchCartCount } = useContext(CartContext);

  const cityOptions = [
    { value: "Abha", label: "Abha" },
    { value: "Ad Dilam", label: "Ad Dilam" },
    { value: "Ad Diriyah", label: "Ad Diriyah" },
    { value: "Afif", label: "Afif" },
    { value: "Ahad Al Musarihah", label: "Ahad Al Musarihah" },
    { value: "Ahad Rafidah", label: "Ahad Rafidah" },
    { value: "Al Aflaj (Layla)", label: "Al Aflaj (Layla)" },
    { value: "Al Badai", label: "Al Badai" },
    { value: "Al Bashayer", label: "Al Bashayer" },
    { value: "Al Baha", label: "Al Baha" },
    { value: "Al Bukayriyah", label: "Al Bukayriyah" },
    { value: "Al Ghat", label: "Al Ghat" },
    { value: "Al Hariq", label: "Al Hariq" },
    { value: "Al Hofuf", label: "Al Hofuf" },
    { value: "Al Jubail", label: "Al Jubail" },
    { value: "Al Jumum", label: "Al Jumum" },
    { value: "Al Kharj", label: "Al Kharj" },
    { value: "Al Khafji", label: "Al Khafji" },
    { value: "Al Khubar", label: "Al Khubar" },
    { value: "Al Khurmah", label: "Al Khurmah" },
    { value: "Al Majardah", label: "Al Majardah" },
    { value: "Al Majma'ah", label: "Al Majma'ah" },
    { value: "Al Midhnab", label: "Al Midhnab" },
    { value: "Al Muzahimiyah", label: "Al Muzahimiyah" },
    { value: "Al Qatif", label: "Al Qatif" },
    { value: "Al Qurayyat", label: "Al Qurayyat" },
    { value: "Al Wajh", label: "Al Wajh" },
    { value: "Al-Dawadmi", label: "Al-Dawadmi" },
    { value: "An Nuayriyah", label: "An Nuayriyah" },
    { value: "Ar Rass", label: "Ar Rass" },
    { value: "Arar", label: "Arar" },
    { value: "As Sulayyil", label: "As Sulayyil" },
    { value: "Ash Shinan", label: "Ash Shinan" },
    { value: "Ash Shimasiyah", label: "Ash Shimasiyah" },
    { value: "Aqiq", label: "Aqiq" },
    { value: "Az Zulfi", label: "Az Zulfi" },
    { value: "Badr", label: "Badr" },
    { value: "Billasmar", label: "Billasmar" },
    { value: "Bishah", label: "Bishah" },
    { value: "Buqayq", label: "Buqayq" },
    { value: "Buraydah", label: "Buraydah" },
    { value: "Dammam", label: "Dammam" },
    { value: "Dawmat Al Jandal", label: "Dawmat Al Jandal" },
    { value: "Dhahran", label: "Dhahran" },
    { value: "Dhurma", label: "Dhurma" },
    { value: "Duba", label: "Duba" },
    { value: "Hafar al-Batin", label: "Hafar al-Batin" },
    { value: "Haqil", label: "Haqil" },
    { value: "Hail", label: "Hail" },
    { value: "Hawtat Bani Tamim", label: "Hawtat Bani Tamim" },
    { value: "Inak", label: "Inak" },
    { value: "Jazan", label: "Jazan" },
    { value: "Jeddah", label: "Jeddah" },
    { value: "Jouf", label: "Jouf" },
    { value: "Khamis Mushait", label: "Khamis Mushait" },
    { value: "Khaybar", label: "Khaybar" },
    { value: "Khulais", label: "Khulais" },
    { value: "King Abdullah Economic City",
      label: "King Abdullah Economic City",
    },
    { value: "Laith", label: "Laith" },
    { value: "Layla", label: "Layla" },
    { value: "Madinah", label: "Madinah" },
    { value: "Makkah", label: "Makkah" },
    { value: "Muhayil Aseer", label: "Muhayil Aseer" },
    { value: "Mudhaylif", label: "Mudhaylif" },
    { value: "Najran", label: "Najran" },
    { value: "Qaryat Al Ulya", label: "Qaryat Al Ulya" },
    { value: "Qassim", label: "Qassim" },
    { value: "Quwayiyah", label: "Quwayiyah" },
    { value: "Rafha", label: "Rafha" },
    { value: "Rania", label: "Rania" },
    { value: "Ras Tannurah", label: "Ras Tannurah" },
    { value: "Riyad Al Khabra", label: "Riyad Al Khabra" },
    { value: "Riyadh", label: "Riyadh" },
    { value: "Rabigh", label: "Rabigh" },
    { value: "Sabya", label: "Sabya" },
    { value: "Safwa", label: "Safwa" },
    { value: "Sakaka", label: "Sakaka" },
    { value: "Samtah", label: "Samtah" },
    { value: "Sayhat", label: "Sayhat" },
    { value: "Sharurah", label: "Sharurah" },
    { value: "Shaqra", label: "Shaqra" },
    { value: "Shraiee", label: "Shraiee" },
    { value: "Sakaka", label: "Sakaka" },
    { value: "Tabuk", label: "Tabuk" },
    { value: "Taif", label: "Taif" },
    { value: "Tathilith", label: "Tathilith" },
    { value: "Tarut", label: "Tarut" },
    { value: "Thadiq", label: "Thadiq" },
    { value: "Thuwal", label: "Thuwal" },
    { value: "Turaif", label: "Turaif" },
    { value: "Turaib", label: "Turaib" },
    { value: "Turbah", label: "Turbah" },
    { value: "Umluj", label: "Umluj" },
    { value: "Unaizah", label: "Unaizah" },
    { value: "Uqlat Al Suqur", label: "Uqlat Al Suqur" },
    { value: "Wadi El Dwaser", label: "Wadi El Dwaser" },
    { value: "Yanbu", label: "Yanbu" },
    { value: "Zalal", label: "Zalal" },
  ];

  const [postalCode, setPostalCode] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [lastName, setLastName] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [address, setAddress] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [apartment, setApartment] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [firstName, setFirstName] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  useEffect(() => {
    handleGetCart();
    handleGetUser();
    getShippmentInfo();
  }, []);

  async function handleSubmitMobile() {
    setLoading(true);
    navigate(`/cart/${cart.cart_id}`);
    setLoading(false);
  }
  async function getShippmentInfo() {
    setLoading(true);
    const saudiArabia = Country.getCountryByCode("SA");
    const countryOptions = [
      { value: saudiArabia.isoCode, label: saudiArabia.name },
    ];
    setCountryOptions(countryOptions);

    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    try {
      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/getShipmentInfo",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();

      if (
        responseData.error ===
          "You dont have cart yet to fill out shipmet info." ||
        responseData.error === "You dont have shipmet info."
      ) {
      } else {
        setEmail({
          value: responseData.email,
          isTouched: true,
          isValid: true,
        });
        setFirstName({
          isTouched: true,
          value: responseData.first_name,
          isValid: true,
        });
        setPostalCode({
          isTouched: false,
          value: responseData.postal_code,
        });
        setLastName({
          isTouched: true,
          value: responseData.last_name,
          isValid: true,
        });
        setAddress({
          isTouched: true,
          value: responseData.address,
          isValid: true,
        });
        setApartment({
          isTouched: true,
          value: responseData.appartment,
          isValid: true,
        });
        setPhoneNumber({
          isTouched: true,
          value: responseData.phone,
          isValid:
            /^\d{10}$/.test(responseData.phone) &&
            responseData.phone.startsWith("05"),
        });
        setReceiveEmailsChecked(responseData.news_offers_flag === 1);
        setSaveAddress(responseData.save_address_flag === 1);

        const selectedCountryFromResponse = countryOptions.find(
          (country) => country.value === responseData.country
        );

        if (selectedCountryFromResponse) {
          setSelectedCountry(selectedCountryFromResponse);

          // const states = await State.getStatesOfCountry(
          //   selectedCountryFromResponse.value
          // );

          // const stateOptions = states.map((state) => ({
          //   value: state.isoCode,
          //   label: state.name,
          // }));

          // setStateOptions(stateOptions);

          // const selectedStateFromResponse = stateOptions.find(
          //   (state) => state.label === responseData.state
          // );

          // if (selectedStateFromResponse) {
          //   setSelectedState(selectedStateFromResponse);

          //   const cities = await City.getCitiesOfState(
          //     selectedCountryFromResponse.value,
          //     selectedStateFromResponse.value
          //   );

          //   const cityOptions = cities.map((city) => ({
          //     value: city.id,
          //     label: city.name,
          //   }));
          //   // setCityOptions(cityOptions);

          const selectedCityFromResponse = cityOptions.find(
            (city) => city.label === responseData.city
          );
          if (selectedCityFromResponse) {
            setSelectedCity(selectedCityFromResponse);
          }
          //}
        }
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    const formData = {
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      phone: phoneNumber.value,
      address: address.value,
      country: selectedCountry.value,
      // state:selectedState.label,
      city: selectedCity.label,
      postal_code: postalCode.value,
      appartment: apartment.value,
      news_offers_flag: receiveEmailsChecked ? 1 : 0,
      save_address_flag: saveAddress ? 1 : 0,
    };

    try {
      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/addShipmentInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData), // Stringify the JSON object
        }
      );
      const responseData = await response.json();

      if (response.ok) {
        navigate(`/cart/${cart.cart_id}/shipping`);
      } else {
        // Handle server errors
        console.error("Server error:", responseData.error);
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error.message);
      setLoading(false);
      setError(true);
    }
  }
  const handleGetUser = () => {
    const user = User.fromLocalStorage();

    if (user) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  };
  const generateGuestSessionId = () => {
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    return `guest_${randomNumber}`;
  };
  const removeProductFromCart = async (productId) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/removeProductFromCart?product_id=${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setLoading(true);
        handleGetCart();
        fetchCartCount();
      }
    } catch (error) {
      console.error("Error while removing product from cart", error);
    }
  };
  const editProductInCart = async (productId, quantity) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/editCart?product_id=${productId}&quantities=${quantity}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        await handleGetCart();
      } else {
        console.error("Error updating product quantity:", response.statusText);
      }

      return responseData; // Return the response data
    } catch (error) {
      console.error("Error updating product quantity:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleGetCart = async () => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/viewCart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      if (responseData.message === "Cart is empty") {
        setCartEmpty(true);
        // navigate(`/cart`);
      } else {
        setSubtotal(responseData.sub_total);
        setProducts(responseData.cart);

        setCart(responseData);
      }
    } catch (error) {
      console.error("Error while viewing cart", error);
    } finally {
      setLoading(false);
    }
  };
  function handleEmailInput(e) {
    setEmail({
      isTouched: true,
      value: e.target.value,
      isValid: validateEmail(e.target.value),
    });
  }
  function handleFirstNameInput(e) {
    let firstName = e.target.value;

    // Capitalize the first letter
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

    // Allow English, Arabic characters, and spaces
    const isValid =
      /^[\u0600-\u06FFa-zA-Z\s]+$/.test(firstName) && firstName.length > 3;

    setFirstName({
      isTouched: true,
      value: firstName,
      isValid: isValid,
    });
  }

  function handleLastNameInput(e) {
    let lastName = e.target.value;

    // Capitalize the first letter
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    // Allow English, Arabic characters, and spaces
    const isValid =
      /^[\u0600-\u06FFa-zA-Z\s]+$/.test(lastName) && lastName.length > 3;

    setLastName({
      isTouched: true,
      value: lastName,
      isValid: isValid,
    });
  }
  function handleAddressInput(e) {
    let address = e.target.value;

    // Capitalize the first letter
    address = address.charAt(0).toUpperCase() + address.slice(1);

    // Allow any characters (including Arabic and white spaces) and minimum length of 10 characters
    const isValid = /^[\u0600-\u06FF\s\S]{10,}$/u.test(address); // Match any character (including Arabic and white spaces)

    setAddress({
      isTouched: true,
      value: address,
      isValid: isValid,
    });
  }

  function handleApartmentInput(e) {
    let apartment = e.target.value;

    // Capitalize the first letter
    apartment = apartment.charAt(0).toUpperCase() + apartment.slice(1);

    // Allow any characters (including Arabic and white spaces) and minimum length of 3 characters
    const isValid = /^[\u0600-\u06FF\s\S]{1,}$/u.test(apartment); // Match any character (including Arabic and white spaces)

    setApartment({
      isTouched: true,
      value: apartment,
      isValid: isValid,
    });
  }

  function handlePostalCodeInput(e) {
    // Convert Arabic numerals to Western numerals
    const convertToWesternNumbers = (str) => {
      const arabicToWestern = {
        '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
        '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
      };
      return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, match => arabicToWestern[match]);
    };
  
    let postalCode = e.target.value;
  
    // Convert Arabic numbers to Western numbers
    postalCode = convertToWesternNumbers(postalCode);
  
    // Remove any non-digit characters from the input
    postalCode = postalCode.replace(/\D/g, "");
  
    // Check if the postal code is valid for Saudi Arabia (5 digits)
    const isValid = /^\d{5}$/.test(postalCode);
  
    setPostalCode({
      isTouched: true,
      value: postalCode,
      isValid: isValid,
    });
  }

  function handleReceiveEmailsChange() {
    setReceiveEmailsChecked(!receiveEmailsChecked);
  }
  function handleSaveAddressChange() {
    setSaveAddress(!saveAddress);
  }
  function handlePhoneNumberInput(e) {
    // Convert Arabic numerals to Western numerals
    const convertToWesternNumbers = (str) => {
      const arabicToWestern = {
        '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
        '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
      };
      return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, match => arabicToWestern[match]);
    };
  
    const value = e.target.value;
    const westernValue = convertToWesternNumbers(value);
  
    setPhoneNumber({
      isTouched: true,
      value: westernValue,
      isValid:
        /^\d{10}$/.test(westernValue) && westernValue.startsWith("05"),
    });
  }
  
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const states = State.getStatesOfCountry(selectedOption.value);
    const stateOptions = states.map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(stateOptions);
    setSelectedState(null);
    setSelectedCity(null);
  };
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={downArrow}
          alt="Right Arrow"
          style={{ width: 20, height: 20 }}
        />
      </components.DropdownIndicator>
    );
  };

  const formIsValid =
    firstName.isValid &&
    lastName.isValid &&
    address.isValid &&
    (!authenticated ? email.isValid : true) &&
    phoneNumber.isValid &&
    apartment.isValid &&
    selectedCountry !== null &&
    // selectedState !== null &&
    selectedCity !== null;

 

  return (
    <main>
      <div className="mobile-cart">
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader-overlay">
              <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
            </div>
          </div>
        ) : (
          <>
            <div className="cart-container">
              <div className="my-cart">{t("myCart")}</div>
              {cartEmpty ? (
                <div className="empty-cart">
                  <div className="empty-cart-message">
                    {t("yourCartIsEmpty")}&nbsp;{" "}
                    <Link
                      to="/products"
                      className="empty-cart-message-underline"
                    >
                      {" "}
                      {t("continueShopping")}{" "}
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    {products &&
                      products.length > 0 &&
                      products.map((product, index) => (
                        <div className="single-product-coloum" key={index}>
                          <div className="single-product-cart">
                            {product.medias && product.medias.length > 0 && (
                              <img
                                src={
                                  product.medias.find(
                                    (media) => media.tag === "rectangle"
                                  ).image_url
                                }
                                alt={product.tag}
                                height={130} 
                              />
                            )}

                            <div
                              className={`single-coloum ${
                                isArabic ? "arabic" : ""
                              }`}
                            >
                              <div
                                className="product-name"
                                style={{ display: "block" }}
                              >
                                {isArabic ? product.name_ar : product.name_en}
                              </div>
                              <div className="product-price">
                                
                                  {product.status === "Sale"
                                    ? product.discountedPrice
                                    : product.price}
                                {" "}
                                {t("SAR")}
                              </div>
                              <div className="product-conter">
                                <CounterBox
                                  ProductColor={"rgb(252, 219, 204)"}
                                  quantity={product.quantity}
                                  productId={product.id}
                                  productLimit={product.product_limit}
                                  onUpdate={(productId, quantity) =>
                                    editProductInCart(productId, quantity)
                                  }
                                />
                                <div
                                  className="product-remove"
                                  onClick={() =>
                                    removeProductFromCart(product.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("remove")}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index < products.length - 1 && (
                            <img
                              src={BlackLine}
                              alt="blackLine"
                              className="blackLine"
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
            {/* Include checkout-container inside the conditional rendering block */}
            {!cartEmpty && (
              <div className="checkout-container">
                <div className="subtotal-price">
                  <div className="subtotal-text">{t("subtotal")}</div>
                  <div className="price-text">
                   {subtotal} {t("SAR")}
                  </div>
                </div>
                <div className="button-cart-container">
                  <Button
                    className="shipping-button-cart-mobile"
                    text={t("checkOut")}
                    onClick={handleSubmitMobile}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="pc-cart">
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader-overlay">
              <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
            </div>
          </div>
        ) : (
          <>
            {cartEmpty ? (
              <div className="empty-cart">
                <div className="empty-cart-message">
                  {t("yourCartIsEmpty")}&nbsp;{" "}
                  <Link to="/products" className="empty-cart-message-underline">
                    {" "}
                    {t("continueShopping")}{" "}
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <div className="Left-Align-cart">
                  {!authenticated && (
                    <div className="header-cart-pc">
                      <div className="contact-title-cart-pc">
                        {t("contact")}
                      </div>

                      <div className="signUp-logIn-cart-pc">
                        {t("haveAnAccount")}{" "}
                        <a href="/login">
                          <span className="underline-text-cart-pc">
                            {t("logIn")}
                          </span>
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="order-Information-cart-pc">
                    {!authenticated && (
                      <div style={{ color: "rgb(129,129,129)" }}>
                        <Input
                          name="email"
                          type="text"
                          onChange={handleEmailInput}
                          isValid={email.isValid}
                          isTouched={email.isTouched}
                          placeholder={t("Email")}
                          value={email.value}
                          errorMsg={t("Enter a valid email")}
                        />

                        <div className="receive-emails-cart-pc">
                          <Checkbox
                            className="checkbox-rose-cart-pc"
                            checked={receiveEmailsChecked}
                            onChange={handleReceiveEmailsChange}
                            size="medium"
                            sx={{
                              color: "rgba(252, 219, 204, 1)",
                              fontSize: 1,
                              "&.Mui-checked": {
                                color: "rgba(252, 219, 204, 1)",
                                fontSize: 1,
                              },
                            }}
                          />
                          {t("notifyWithOffers")}
                        </div>
                      </div>
                    )}

                    <div className="shipping-title-cart-pc">
                      {t("shippingAddress")}{" "}
                    </div>

                    <div className="sign-up-info-cart-pc">
                      <Input
                        name="firstName"
                        type="text"
                        onChange={handleFirstNameInput}
                        isValid={firstName.isValid}
                        isTouched={firstName.isTouched}
                        placeholder={t("First Name")}
                        value={firstName.value}
                        errorMsg={t("Enter a valid first name")}
                      />
                      <Input
                        name="lastName"
                        type="text"
                        onChange={handleLastNameInput}
                        isValid={lastName.isValid}
                        isTouched={lastName.isTouched}
                        placeholder={t("Last Name")}
                        value={lastName.value}
                        errorMsg={t("Enter a valid last name")}
                      />

                      <div className="selector-cart-pc">
                        <Select
                          className="delect-cart-pc"
                          options={countryOptions}
                          onChange={handleCountryChange}
                          value={selectedCountry}
                          placeholder={t("selectCountry")}
                          components={{ DropdownIndicator }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              border: "none",
                              width: "100%", // Set custom width
                              display: "flex",
                              alignItems: "center",
                              borderColor: state.isFocused
                                ? "transparent"
                                : provided.borderColor, // Remove border color on focus
                              boxShadow: state.isFocused
                                ? "none"
                                : provided.boxShadow,
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "rgb(129,129,129)", // Change the color of the dropdown indicator
                              marginLeft: "auto", // Move the dropdown indicator to the end
                              strokeWidth: 1, // Adjust the thickness of the arrow
                            }),
                            indicatorSeparator: () => ({
                              display: "none", // Hide the separator between dropdown indicator and the select
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "rgb(255,255,255)", // Set transparent background for dropdown menu
                              border: "none", // Remove border around the dropdown
                              marginTop: 0, // Remove default margin
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "your_selected_color"
                                : "transparent", // Change background color for selected option
                              color: "your_text_color", // Change text color for options
                              border: "none",
                              "&:hover": {
                                backgroundColor: "transparent", // Change background color on hover
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "your_text_color", // Change text color for single value
                              marginLeft: "2%",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "rgba(129, 129, 129, 1)",
                              marginLeft: "2%", // Change color of placeholder text
                            }),
                          }}
                        />
                      </div>

                      <div className="selector-cart-pc">
                        <Select
                          className="delect-cart-pc"
                          options={cityOptions}
                          onChange={handleCityChange}
                          value={selectedCity}
                          placeholder={t("selectCity")}
                          isDisabled={!selectedCountry}
                          components={{ DropdownIndicator }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              border: "none",
                              width: "100%", // Set custom width
                              display: "flex",
                              alignItems: "center",
                              borderColor: state.isFocused
                                ? "transparent"
                                : provided.borderColor, // Remove border color on focus
                              boxShadow: state.isFocused
                                ? "none"
                                : provided.boxShadow,
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "rgb(129,129,129)", // Change the color of the dropdown indicator
                              marginLeft: "auto", // Move the dropdown indicator to the end
                            }),
                            indicatorSeparator: () => ({
                              display: "none", // Hide the separator between dropdown indicator and the select
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "rgb(255,255,255)", // Set transparent background for dropdown menu
                              border: "none",
                              marginTop: 0, // Remove default margin
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "your_selected_color"
                                : "transparent", // Change background color for selected option
                              color: "your_text_color",
                              // Change text color for options
                              "&:hover": {
                                backgroundColor: "transparent", // Change background color on hover
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "your_text_color",
                              marginLeft: "2%", // Change text color for single value
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "rgba(129, 129, 129, 1)",
                              marginLeft: "2%", // Change color of placeholder text
                            }),
                          }}
                        />
                      </div>

                      <Input
                        name="address"
                        type="text"
                        onChange={handleAddressInput}
                        isValid={address.isValid}
                        isTouched={address.isTouched}
                        placeholder={t("address")}
                        value={address.value}
                        errorMsg={t("enterValidAddress")}
                      />

                      <Input
                        name="apartment"
                        type="text"
                        onChange={handleApartmentInput}
                        isValid={apartment.isValid}
                        isTouched={apartment.isTouched}
                        placeholder={t("appartment")}
                        value={apartment.value}
                        errorMsg={t("enterValidAppartment")}
                      />
                      <Input
                        name="postalCode"
                        type="text"
                        onChange={handlePostalCodeInput}
                        isValid={postalCode.isValid}
                        isTouched={postalCode.isTouched}
                        placeholder={t("postalCode")}
                        value={postalCode.value}
                        errorMsg={t("enterValidPostalCode")}
                      />

                      <Input
                        name="phoneNumber"
                        type="text"
                        onChange={handlePhoneNumberInput}
                        isValid={phoneNumber.isValid}
                        isTouched={phoneNumber.isTouched}
                        placeholder={t("phone_placeholder")}
                        value={phoneNumber.value}
                        errorMsg={t("valid_phone_error")}
                      />
                    </div>
                    {authenticated && (
                      <div className="save-info-cart-pc">
                        <Checkbox
                          className="checkbox-rose-cart-pc"
                          checked={saveAddress}
                          onChange={handleSaveAddressChange}
                          size="medium"
                          sx={{
                            color: "rgba(252, 219, 204, 1)",
                            fontSize: 1,
                            "&.Mui-checked": {
                              color: "rgba(252, 219, 204, 1)",
                              fontSize: 1,
                            },
                          }}
                        />
                        {t("saveInfo")}
                      </div>
                    )}

                    <Link to={"/cart"}>
                      <div
                        className={`return-shopping-pc ${
                          isArabic ? "arabic" : ""
                        }`}
                      >
                        {t("continueShopping")}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="Right-Align-cart">
                  <div>
                    {products &&
                      products.length > 0 &&
                      products.map((product, index) => (
                        <div className="single-product-coloum" key={index}>
                          <div className="single-product-cart">
                            {product.medias && product.medias.length > 0 && (
                              <img
                                src={
                                  product.medias.find(
                                    (media) => media.tag === "rectangle"
                                  ).image_url
                                }
                                alt={product.tag}
                                height={130}
                              />
                            )}

                            <div
                              className={`single-coloum ${
                                isArabic ? "arabic" : ""
                              }`}
                            >
                              <div className="product-name">
                                {isArabic ? product.name_ar : product.name_en}
                              </div>
                              <div className="product-price">
                                
                                  {product.status === "Sale"
                                    ? product.discountedPrice
                                    : product.price}
                                  
                                {" "}             
                                {t("SAR")}
                              </div>

                              <div className="product-conter">
                                <CounterBox
                                  ProductColor={"rgb(252, 219, 204)"}
                                  quantity={product.quantity}
                                  productId={product.id}
                                  productLimit={product.product_limit}
                                  onUpdate={(productId, quantity) =>
                                    editProductInCart(productId, quantity)
                                  }
                                />
                                <div
                                  className="product-remove"
                                  onClick={() =>
                                    removeProductFromCart(product.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("remove")}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index < products.length && (
                            <img
                              src={BlackLine}
                              alt="blackLine"
                              className="blackLine"
                            />
                          )}
                        </div>
                      ))}
                    <div className="subtotal-container-pc">
                      {!cartEmpty && (
                        <>
                          <div className="row-pc">
                            <div className="title-pc">{t("subtotal")}</div>
                            <div className="value-pc">
                              {subtotal} {t("SAR")}
                            </div>
                          </div>

                          <div className="row-pc">
                            <div className="title-pc">{t("shippingFees")}</div>
                            <div className="value-pc">-</div>
                          </div>
                          <div className="row-pc">
                            <div className="title-pc">{t("total")}</div>
                            <div className="value-pc">
                              {subtotal} {t("SAR")}
                            </div>
                          </div>
                        </>
                      )}
                      <img
                        src={BlackLine}
                        alt="blackLine"
                        className="blackLine"
                      />
                    </div>
                  </div>
                  <div className="button-cart-container">
                    <Button
                      className="shipping-button-cart-pc"
                      text={t("checkOut")}
                      onClick={handleSubmit}
                      disabled={!formIsValid}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Cart;
