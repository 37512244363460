import React from 'react';

const HeartIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 15"
    fill={color}
    width={width}
    height={height}
  >
    <path d="M12.91,5.75c-1.17,2.25-4.3,5.31-6.07,6.94c-0.1903,0.1718-0.4797,0.1718-0.67,0C4.39,11.06,1.26,8,0.09,5.75C-2.48,0.8,4-2.5,6.5,2.45C9-2.5,15.48,0.8,12.91,5.75z" />
  </svg>
);

export default HeartIcon;
