import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const ImageSwiper = ({ images, saleFlag, productTheme, soldOutFlag }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { t } = useTranslation();

  const filteredImages = images.filter(
    (image) => image.tag === "main" || image.tag === "square"
  );

  return (
    <div className="container">
      <div className="main-photo">
        <Swiper
          navigation={false}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {filteredImages.map((image, index) => (
            <SwiperSlide key={index}>
              {saleFlag && (
                <div
                  className="sale-badge"
                  style={{ backgroundColor: productTheme }}
                >
                  {t("Sale")}
                </div>
              )}
              {soldOutFlag && (
                <div
                  className="sale-badge"
                  style={{ backgroundColor: productTheme }}
                >
                  {t("out of stock")}
                </div>
              )}

              <img src={image.image_url} alt={image.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* Thumbnail */}
      <div className="thumbnail">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={0}
          slidesPerView={6}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {filteredImages.map((image, index) => (
            <SwiperSlide key={index}>
              <button>
                <img src={image.image_url} alt={image.alt} />
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ImageSwiper;
