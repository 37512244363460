import React, { useState } from 'react';

const SimpleCounterBox = ({ ProductColor, initialQuantity = 1, maxQuantity = 10, onQuantityChange }) => {
  const [count, setCount] = useState(initialQuantity);

  const handleIncrement = () => {
    const newCount = count < maxQuantity ? count + 1 : count;
    setCount(newCount);
    onQuantityChange(newCount);
  }

  const handleDecrement = () => {
    const newCount = count > 1 ? count - 1 : count;
    setCount(newCount);
    onQuantityChange(newCount);
  }

  return (
    <div className="counter-box-cart" style={{ border: `1px solid ${ProductColor}` }}>
       <div className="counter-container-cart">
      <button onClick={handleDecrement} disabled={count === 1} style={{ color: count === 1 ? 'gray' : 'inherit' }}>
        -
      </button>
      <span>{count}</span>
      <button onClick={handleIncrement} disabled={count >= maxQuantity} style={{ color: count >= maxQuantity ? 'gray' : 'inherit' }}>
        +
      </button>
      </div>
    </div>
  );
};

export default SimpleCounterBox;
