class User {
  constructor(userData) {
    this.id = userData?.id;
    this.first_name = userData?.first_name;
    this.last_name = userData?.last_name;
    this.email = userData?.email;
    this.birthday = userData?.birthday;
    this.phone = userData?.phone;
    this.about_us = userData?.about_us;
    this.accept_promotion = userData?.accept_promotion;
    this.occupation = userData?.occupation;
    this.points = userData?.points;
    this.otp = userData?.otp;
    this.api_token = userData?.api_token;
    this.role = userData?.role;
    this.createdAt = new Date(userData?.created_at);
    this.updatedAt = new Date(userData?.updated_at);
    // Check if shippingaddresses exists and is an array
    this.shippingaddresses = Array.isArray(userData?.shippingaddresses)
      ? userData.shippingaddresses.map(address => ({
          id: address?.id,
          user_id: address?.user_id,
          default_user_flag: address?.default_user_flag,
          first_name: address?.first_name,
          last_name: address?.last_name,
          email: address?.email,
          phone: address?.phone,
          address: address?.address,
          country: address?.country,
          city: address?.city,
          appartment: address?.appartment,
          createdAt: new Date(address?.created_at),
          updatedAt: new Date(address?.updated_at)
        }))
      : [];
    // Check if wishlist exists and is an array
    this.wishlist = Array.isArray(userData?.wishlist)
      ? userData.wishlist.map(item => ({
          id: item?.id,
          user_id: item?.user_id,
          product_id: item?.product_id, // Update property name to match the key
          createdAt: new Date(item?.created_at),
          updatedAt: new Date(item?.updated_at)
        }))
      : [];
  }

  getFullName() {
    return `${this.first_name ?? ''} ${this.last_name ?? ''}`;
  }

  isPromotionAccepted() {
    return this.accept_promotion === 'Yes';
  }

  // Add more methods based on your application's needs

  static fromLocalStorage() {
    const userJSON = localStorage.getItem('user');
    if (userJSON) {
      const userData = JSON.parse(userJSON);
      return new User(userData);
    }
    return null;
  }

  static clearLocalStorage() {
    localStorage.removeItem('user');
  }
}

export default User;
