import React from 'react';
import { useLocation } from 'react-router-dom';

function PaymentResult() {
  const location = useLocation();

  // Parse the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const respMessage = queryParams.get('respMessage');
  const respCode = queryParams.get('respCode');
  const customerEmail = queryParams.get('customerEmail');
  // Add more parameters as necessary

  return (
    <div style={{margin: "200px"}}>
      <h1>Payment Status: {respMessage}</h1>
      <p>Response Code: {respCode}</p>
      <p>Customer Email: {decodeURIComponent(customerEmail)}</p>
      {/* Display more information as needed */}
    </div>
  );
}

export default PaymentResult;
