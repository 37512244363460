import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

export default function ImageSwiperAcc({ images }) {
  const { i18n } = useTranslation();

  const filteredImages = images.filter((image) => {
    if (i18n.language === "ar") {
      return image.tag === "hero_natural_ingredients_ar";
    } else {
      return image.tag === "hero_natural_ingredients";
    }
  });

  return (
    <div className="accordion-swiper">
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation]}
      >
        {filteredImages.map((media, index) => (
          <SwiperSlide key={index}>
            <img src={media.image_url} alt={`Image ${index + 1}`} />
          </SwiperSlide>
        ))}
        <div className="swiper-button-next" style={{ color: "black" }}></div>
        <div className="swiper-button-prev" style={{ color: "black" }}></div>
      </Swiper>
    </div>
  );
}
