import React from "react";
import "../../styles/orderFailed.css";
import { BsFillExclamationOctagonFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrderFailed = () => {
  const { id, id2 } = useParams();
  const { t } = useTranslation();

  return (
    <div className="orderFailed-container">
      <div className="orderFailed-body">
        <BsFillExclamationOctagonFill className="orderFailed-corect-sign" />
        <div className="order-Failed-title">{t("Order_Failed_Title")}</div>
        <div className="order-Failed-text-body">
          {t("Order_Failed_Text_Body")}
        </div>
        <Link to={`/cart/${id}/shipping/${id2}`}>
          <div className="continue-shopping-button-OF">
            {t("Update_Payment_Method_Button")}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default OrderFailed;
