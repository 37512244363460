import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { useTranslation } from "react-i18next";
import '../styles/Searchbar.css';

export default function Searchbar({ defaultValue, hintList }) {
  const [inputValue, setValue] = useState(defaultValue);
  const [showHints, setShowHints] = useState(false);

  const hardcodedHints = ['Atticos', 'Cleansing balm', 'Whitening Cream', 'Stick'];

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    setShowHints(true);
  };

  const handleSearch = () => {
    if (inputValue) {
      window.location.href = `/products/?q=${inputValue}`;
      setValue('');
      hideHints();
    } else {
      window.location.href = '/';
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') return handleSearch();
  };

  const handleHintClick = (hint) => {
    setValue(hint);
    hideHints();
    window.location.href = `/products/?q=${hint}`;
  };

  const hideHints = () => {
    setShowHints(false);
  };

  const handleMouseDown = (event) => {
    // Hide hints when clicking outside the input or hint list
    if (!event.target.closest('.input-bar')) {
      hideHints();
    }
  };
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  // Set the placeholder text dynamically based on the selected language
  const placeholderText = isArabic ? "بحث" : "Search";

  useEffect(() => {
    // Add a global event listener to handle clicks outside the input or hint list
    window.addEventListener('mousedown', handleMouseDown);
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (
    <div className="input-bar">
      <IoSearch className="icon-search" onClick={handleSearch} />
      <input
        type="text"
        id="inputId"
        placeholder={placeholderText}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        className="search-bar"
      />
      {showHints && (
        <ul className="hint-list">
          {hintList
            .filter((hint) => hint.toLowerCase().includes(inputValue.toLowerCase()))
            .map((hint, index) => (
              <li key={`filter-${index}`} onClick={() => handleHintClick(hint)} className="filtered-hint">
                {hint}
              </li>
            ))}
          <li className="popular-options">
            <span className="hint-text">trending searches</span>
          </li>
          {hardcodedHints.map((hint, index) => (
            <li key={`hardcoded-${index}`} onClick={() => handleHintClick(hint)} className="hardcoded-product">
              {hint}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
