import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/accessories.css";
import Footer from "components/Footer";

const Accessories = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="accessories-container">
        <div className="accessories-title">{t("coming_soon")}</div>
        <div className="accessories-italic">{t("pampering_experience")}</div>
      </div>
      <Footer />
    </>
  );
};

export default Accessories;
