import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import RightIcon from "./../../assets/right.svg";
import RightIconAr from "./../../assets/chevron-right-md_ar.svg";
import HeartIcon from "components/HeartIcon";
import Footer from "components/Footer";
import Error from "./../../assets/error.png";
import { SyncLoader } from "react-spinners";
import Navbar from "components/Navbar";
import "./perview.css";
import "../../fonts/fonts.css";
import CartContext from "CartContext";
import ProductImages from "ProductImages/productImages";
import { useTranslation } from "react-i18next";

const PerviewProduct = () => {
  const [product, setProduct] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [productTheme, setProductTheme] = useState("#C6BC7E");
  const { addToCart } = useContext(CartContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    fetch(`https://dashboard.natindamiddleeast.com/api/getproduct/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setProduct(data.product);
        setProductTheme(data.product.theme);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, productTheme]);

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const navbarThemeMap = {
    "#9ECBCE": "#EDF6F6",
    "#FCBAB6": "#FBE9E8",
    "#ECE095": "#FBF9EA",
    "#EEDDB9": "#FAF3E5",
  };

  const navbarTheme =
    navbarThemeMap[productTheme.toUpperCase()] || productTheme;

  return (
    <div>
      {isLoading ? (
        <div className="global-full-page">
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : error ? (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text">{t("OOPS")}</div>
        </div>
      ) : (
        <main>
          <Navbar productTheme={navbarTheme} />

          <div className="perview-product-mobile">
            <div className="path-perview">
              <span>{t("Home")} </span>
              <img
                src={i18n.language === "ar" ? RightIconAr : RightIcon}
                style={{
                  margin: "5px",
                  width: "16px",
                  height: "18px",
                }}
                alt="right-icon"
              />
              <span>{t(product.product_type)}</span>
              <img
                src={RightIcon}
                style={{ marginRight: "2px", width: "12", height: "12" }}
                alt="right-icon"
              />
              <span style={{ fontWeight: 500 }}>
                {" "}
                {product[`name_${i18n.language.slice(-2)}`]}
              </span>
            </div>

            <div className="perview-product-hearts-container">
              {Array.from(
                { length: Math.round(product.avg_rating) },
                (_, index) => (
                  <HeartIcon
                    key={index}
                    color={productTheme}
                    width={25}
                    height={20}
                    alt={`Heart ${index + 1}`}
                  />
                )
              )}
              <div className="perview-product-num_reviews">
                ({product.num_reviews})
              </div>
            </div>

            {/* main product */}
            <Link
              to={`/product/${encodeURIComponent(
                product.name_en.replace(/\s+/g, "-").toLowerCase()
              )}/${product.id}`}
            >
              <div className="perview-product-name">
                {" "}
                {product[`name_${i18n.language.slice(-2)}`]}{" "}
              </div>

              <div className="perview-product-image">
                <ProductImages id={product.id} />
              </div>

              <div className="perview-product-promotional">
                {product.id >= 6 && product.id <= 11 && (
                  <span>
                    {(() => {
                      switch (product.id) {
                        case 6:
                          return (
                            <>
                              {t("skin_confidence")}
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                {t("rising_from_balls")}
                              </span>
                            </>
                          );
                        case 7:
                          return (
                            <>
                              {t("ultimate_moisture_experience")}
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                {t("at_your_fingertips")}
                              </span>
                            </>
                          );
                        case 8:
                          return (
                            <>
                              {t("glass_like_complexion")}
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                {t("starts_with_this_product")}
                              </span>
                            </>
                          );
                        case 9:
                          return (
                            <>
                              {t("uv_protection")}
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                {t("fingertips_anytime_anywhere")}
                              </span>
                            </>
                          );
                        case 10:
                          return (
                            <>
                              {t("embracing_natural_skin")}
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                {t("begins_with_cleansing")}
                              </span>
                            </>
                          );
                        case 11:
                          return (
                            <>
                              {t("power_of_gold")}
                              <span style={{ fontStyle: "italic" }}>
                                {" "}
                                {t("gold_ampoule_serum")}
                              </span>
                            </>
                          );
                        default:
                          return "";
                      }
                    })()}
                  </span>
                )}
              </div>

              <button
                className="perview-product-add-to-cart"
                style={{ background: product.theme }}
                onClick={(event) => handleAddToCart(product.id, event)}
              >
                {t("quick_add")} - {product.price} {t("SAR")}
              </button>
              <Link
                to={`/product/${encodeURIComponent(
                  product.name_en.replace(/\s+/g, "-").toLowerCase()
                )}/${product.id}`}
              >
                <div
                  style={{ color: product.theme }}
                  className="perview-product-more-details"
                >
                  {t("more_details")}
                </div>
              </Link>
            </Link>
            <div className="perview-pair-it-with">{t("Pair it with")}</div>

            {/* pair it with product */}
            <Link
              to={`/product/${encodeURIComponent(
                product.pair_it_with.name_en.replace(/\s+/g, "-").toLowerCase()
              )}/${product.pair_it_with.id}`}
            >
              <div className="perview-product-image">
                <ProductImages id={product.pair_it_with.id} />
              </div>

              <div className="perview-product-pair-hearts-container">
                {Array.from(
                  { length: Math.round(product.pair_it_with.avg_rating) },
                  (_, index) => (
                    <HeartIcon
                      key={index}
                      color={product.pair_it_with.theme}
                      width={25}
                      height={20}
                      alt={`Heart ${index + 1}`}
                    />
                  )
                )}
              </div>
              <div className="perview-pair-it-with-name">
                {product.pair_it_with[`name_${i18n.language.slice(-2)}`]}{" "}
              </div>

              <button
                className="perview-product-add-to-cart"
                style={{ background: product.pair_it_with.theme }}
                onClick={(event) =>
                  handleAddToCart(product.pair_it_with.id, event)
                }
              >
                {t("add_to_cart")} - {product.pair_it_with.price} {t("SAR")}
              </button>
            </Link>
          </div>
          <Footer productTheme={productTheme} />
        </main>
      )}
    </div>
  );
};

export default PerviewProduct;
