import React, { useState } from "react";
import "../../styles/FAQs.css";
import { Link } from "react-router-dom";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const [activeSection, setActiveSection] = useState("orders");
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar"; // Detect if the language is Arabic
  return (
    <>
      <div className="FAQs-Container-PC">
        <div className="left-buttons">
          <div className="profile-left-button">
            {/* <Link onClick={() => setActiveSection("account")}>
              <div
                className={`profile-box ${
                  activeSection === "account" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Account")}
              </div>
            </Link> */}
            <Link onClick={() => setActiveSection("orders")}>
              <div
                className={`profile-box ${
                  activeSection === "orders" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("shipmentInfo")}>
              <div
                className={`profile-box ${
                  activeSection === "shipmentInfo" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Shipment")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("returns")}>
              <div
                className={`profile-box ${
                  activeSection === "returns" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("payment")}>
              <div
                className={`profile-box ${
                  activeSection === "payment" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Payment")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("refunds")}>
              <div
                className={`profile-box ${
                  activeSection === "refunds" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("rewards")}>
              <div
                className={`profile-box ${
                  activeSection === "rewards" ? "active" : ""
                } ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards")}
              </div>
            </Link>
          </div>
        </div>
        <div className="FAQ-Text-Container-PC">
          {activeSection === "rewards" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-1")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-1-sub")}
              </div>
              <div
                className={`question-answer-faq-case ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("FAQ-Rewards-Answer-1A")}
              </div>
              <div
                className={`question-answer-faq-case ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("FAQ-Rewards-Answer-1B")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-1C")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-2-Part-1")}{" "}
                <Link to={"/login"} style={{ color: "blue" }}>
                  {t("FAQ-Rewards-Answer-2-Part-2")}{" "}
                </Link>
                {t("FAQ-Rewards-Answer-2-Part-3")}{" "}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-3")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-4")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-5")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-6")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-6")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-7")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-7")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-8")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-8")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-9")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-9")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-10")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-10")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-11")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-11")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-12")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-12")}
              </div>
            </div>
          )}

          {activeSection === "shipmentInfo" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-1")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-3")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-4")}
              </div>

              {/* <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-5")}
              </div> */}

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-6")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-6")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-7")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-7")}
              </div>
            </div>
          )}

          {activeSection === "orders" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-1")}
              </div>

              {/* <div className={`question-title-faq ${
                isArabic ? "arabic" : ""
              }`}>
                {t("FAQ-Orders-Question-2")}
                </div>
              <div className={`question-answer-faq ${
                isArabic ? "arabic" : ""
              }`}>
                {t("FAQ-Orders-Answer-2")}
              </div> */}

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-3A")}

                <br />
                {t("FAQ-Orders-Answer-3B")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-4")}
              </div>

              {/* <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-5")}
              </div> */}
            </div>
          )}

          {activeSection === "returns" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-1A")}
                <br />
                {t("FAQ-Returns-Answer-1B")}
                <br />
                {t("FAQ-Returns-Answer-1C")}
                <br />
                {t("FAQ-Returns-Answer-1D")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-3A")}
                <br />
                {t("FAQ-Returns-Answer-3B")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-4")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-5")}
              </div>
            </div>
          )}

          {activeSection === "payment" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Payment-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Payment-Answer-1")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Payment-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Payment-Answer-2")}
              </div>
            </div>
          )}

          {activeSection === "refunds" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-1A")}
                <br />
                {t("FAQ-Refunds-Answer-1B")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-3")}{" "}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-3A")}
                <br />
                {t("FAQ-Refunds-Answer-3B")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-4")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-5")}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="FAQs-Container-Mobile">
        <div className="faqs-left-buttons">
          <div className="faqs-left-button-coloum">
            {/* <div className="faqs-button">
              <Link onClick={() => setActiveSection("account")}>
                <div
                  className={`faq-box ${
                    activeSection === "account" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Account")}
                </div>
              </Link>
            </div> */}
            <div className="faqs-button">
              <Link onClick={() => setActiveSection("payment")}>
                <div
                  className={`faq-box ${
                    activeSection === "payment" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Payment")}
                </div>
              </Link>
            </div>
            <div className="faqs-button">
              <Link onClick={() => setActiveSection("orders")}>
                <div
                  className={`faq-box ${
                    activeSection === "orders" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Orders")}
                </div>
              </Link>
            </div>
          </div>
          <div className="faqs-left-button-coloum">
            <div className="faqs-button">
              <Link onClick={() => setActiveSection("shipmentInfo")}>
                <div
                  className={`faq-box ${
                    activeSection === "shipmentInfo" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Shipment")}
                </div>
              </Link>
            </div>
            <div className="faqs-button">
              <Link onClick={() => setActiveSection("refunds")}>
                <div
                  className={`faq-box ${
                    activeSection === "refunds" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Refunds")}
                </div>
              </Link>
            </div>
          </div>
          <div className="faqs-left-button-coloum">
            <div className="faqs-button">
              <Link onClick={() => setActiveSection("returns")}>
                <div
                  className={`faq-box ${
                    activeSection === "returns" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Returns")}
                </div>
              </Link>
            </div>
            <div className="faqs-button">
              <Link onClick={() => setActiveSection("rewards")}>
                <div
                  className={`faq-box ${
                    activeSection === "rewards" ? "active" : ""
                  } ${isArabic ? "arabic" : ""}`}
                >
                  {t("FAQ-Rewards")}
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="FAQ-Text-Container-PC">
          {activeSection === "rewards" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-1")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-1-sub")}
              </div>
              <div
                className={`question-answer-faq-case ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("FAQ-Rewards-Answer-1A")}
              </div>
              <div
                className={`question-answer-faq-case ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("FAQ-Rewards-Answer-1B")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-1C")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-3")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-4")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-5")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-6")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-6")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-7")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-7")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-8")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-8")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-9")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-9")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-10")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-10")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-11")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-11")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Rewards-Question-12")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Rewards-Answer-12")}
              </div>
            </div>
          )}

          {activeSection === "shipmentInfo" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-1")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-3")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-4")}
              </div>

              {/* <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-5")}
              </div> */}

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-6")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-6")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-ShipmentInfo-Question-7")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-ShipmentInfo-Answer-7")}
              </div>
            </div>
          )}

          {activeSection === "orders" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-1")}
              </div>

              {/* <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-2")}
              </div> */}

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-3A")}

                <br />
                {t("FAQ-Orders-Answer-3B")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-4")}
              </div>

              {/* <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Orders-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-5")}
              </div> */}
            </div>
          )}

          {activeSection === "returns" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-1A")}
                <br />
                {t("FAQ-Returns-Answer-1B")}
                <br />
                {t("FAQ-Returns-Answer-1C")}
                <br />
                {t("FAQ-Returns-Answer-1D")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-3")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-3A")}
                <br />
                {t("FAQ-Returns-Answer-3B")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Orders-Answer-4")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Returns-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Returns-Answer-5")}
              </div>
            </div>
          )}

          {activeSection === "payment" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Payment-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Payment-Answer-1")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Payment-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Payment-Answer-2")}
              </div>
            </div>
          )}

          {activeSection === "refunds" && (
            <div
              className={`single-question-container ${
                isArabic ? "arabic" : ""
              }`}
            >
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-1")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-1A")}
                <br />
                {t("FAQ-Refunds-Answer-1B")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-2")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-2")}
              </div>

              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-3")}{" "}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-3A")}
                <br />
                {t("FAQ-Refunds-Answer-3B")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-4")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-4")}
              </div>
              <div className={`question-title-faq ${isArabic ? "arabic" : ""}`}>
                {t("FAQ-Refunds-Question-5")}
              </div>
              <div
                className={`question-answer-faq ${isArabic ? "arabic" : ""}`}
              >
                {t("FAQ-Refunds-Answer-5")}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQs;
