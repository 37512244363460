import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProgressBar from 'components/ProgressBar';
import HeartIcon from 'components/HeartIcon';
import Footer from 'components/Footer';
import './reviews.css';
import './../../fonts/fonts.css'

const Reviews = () => {
  const { id } = useParams(); // Get the product ID from route parameters
  const [reviews, setReviews] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [ratingCounts, setRatingCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [productTheme, setProductTheme] = useState('#C6BC7E'); // Set an initial default theme

  useEffect(() => {
    const getProductReviews = async () => {
      try {
        const response = await fetch(`https://dashboard.natindamiddleeast.com/api/getProductReviews/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setReviews(data.reviews);
        // Round avg_rating to 1 decimal place
        setAvgRating(parseFloat(data.avg_rating).toFixed(1));
        setRatingCounts(data.rating_counts);
        setProductTheme(data.theme);

      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    };

    getProductReviews();
  }, [id]); // Pass the product ID to the dependency array

  if (loading) {
    return <div style={{ height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>Loading...</div>;
  }

  return (
    <main>
      <div className="reviews-container">
        <div className="headline-row">
          <div className="headline">Ratings and reviews</div>
          <div className="write-review" style={{ color: productTheme }}>
            <Link to={`/products/${id}/reviews/new`} state={{ productTheme, id }}>
              Write a review
            </Link>
          </div>
        </div>

        <div className='ratings'>
          <div className='ratings-details'>
            {[5, 4, 3, 2, 1].map((number) => (
              <div key={number} className="rating">
                <div className="number">{number}</div>
                <ProgressBar color={productTheme} percentage={(ratingCounts && ratingCounts[number]) || 0} />
              </div>
            ))}
          </div>
          <div className='ratings-number'>
            <div className='big-number'>{avgRating}</div>
            <HeartIcon color={productTheme} width={18} height={16} alt={`Heart`} />
          </div>
        </div>

        <div className='authenticity'>
          To guarantee the authenticity of product reviews,
          a third-party organization takes charge of their
          management. This external entity plays a pivotal role
          in overseeing and validating the reviews, adding an
          extra layer of credibility to the evaluation process.
        </div>

        <div className="reviews">
          {reviews.map((review) => (
            <div key={review.id} className="review-item">
              <div className="title">{review.title}</div>
              <div className="hearts-container">
                {Array.from({ length: Math.round(review.rating) }, (_, index) => (
                  <HeartIcon key={index} color={productTheme} width={16} height={14} alt={`Heart ${index + 1}`} />
                ))}
              </div>

              <div className="comment">{review.review_comment}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer productTheme={productTheme} />
    </main>
  );
};

export default Reviews;
