import React, { useState,useContext } from 'react';
import CartContext from 'CartContext';
import { useTranslation } from "react-i18next";

const CounterBox = ({ ProductColor, quantity, productId, onUpdate, productLimit }) => {
  const [count, setCount] = useState(quantity && quantity > 0 ? quantity : 1);
  const [loadingIncrement, setLoadingIncrement] = useState(false);
  const [loadingDecrement, setLoadingDecrement] = useState(false);
  const { fetchCartCount } = useContext(CartContext);


  const handleIncrement = async () => {
    const newCount = count + 1;
    setLoadingIncrement(true);
    setLoadingDecrement(true);

    const response = await onUpdate(productId, newCount);

    if(response.message ==="Cart updated successfully."){
      await fetchCartCount(); 
      setLoadingIncrement(false);
      setLoadingDecrement(false);
      setCount(count +1);
    }
    else{
      setLoadingDecrement(false);
      setCount(response.product_limit)
    }
    
  }

  const handleDecrement = async () => {
    const newCount = count - 1;
    setLoadingIncrement(true);
    setLoadingDecrement(true);
    await onUpdate(productId, newCount);

    if(newCount>0){
      await fetchCartCount(); 
      setLoadingIncrement(false);
      setLoadingDecrement(false);
      setCount(count -1);

    }
    else{
      setLoadingIncrement(false);

    }

   

  }

  
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

    // Function to convert numbers to Arabic numerals
    const convertToArabicNumerals = (num) => {
      const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return num.toString().replace(/[0-9]/g, (digit) => arabicNumerals[digit]);
    };

     // Conditionally convert the count to Arabic numerals if the language is Arabic
  const displayCount = isArabic ? convertToArabicNumerals(count) : count;

    return (
      <div className="counter-box-cart" style={{ border: `1px solid ${ProductColor}` }}>
        <div className="counter-container-cart">
          <button onClick={handleDecrement} disabled={loadingDecrement || count === 1} style={{ color: loadingDecrement || count === 1 ? 'gray' : 'inherit' }}>-</button>
          <span className="counter-value">{count}</span>
          <button onClick={handleIncrement} disabled={loadingIncrement || count >= 10 } style={{ color: loadingIncrement || count >= 10  ? 'gray' : 'inherit' }}>+</button>
        </div>
      </div>
    );
  };
  
  export default CounterBox;
  