import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import User from "User";
import { IoIosHeart } from "react-icons/io";
import { IoIosHeartEmpty } from "react-icons/io";

import { useTranslation } from "react-i18next";

export default function WriteReview({ ProductColor, ProductId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(1);
  const [alertType, setAlertType] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleCloseAlert = () => {
    setAlertType(null);
  };

  const handleHeartClick = (value) => {
    setRating(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const storedUser = User.fromLocalStorage();

    if (!storedUser) {
      navigate("/login");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData(event.currentTarget);
      formData.append("product_id", ProductId);
      formData.append("rating", rating);

      const headers = {
        Authorization: `Bearer ${storedUser.api_token}`,
      };

      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/createReview",
        {
          method: "POST",
          body: formData,
          headers: headers,
        }
      );

      if (response.ok) {
        setAlertType("success");
      } else {
        setAlertType("error");
      }
    } catch (error) {
      setAlertType("warning");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      id="write-review"
      className="write-review-container"
      onSubmit={handleSubmit}
    >
      <div className="question">{t("Name")}*</div>
      <input
        className="answer"
        type="text"
        name="name"
        style={{ backgroundColor: `${ProductColor}30` }}
        required
      />

      <div className="question">{t("Review Title")}*</div>
      <input
        className="answer"
        type="text"
        name="title"
        style={{ backgroundColor: `${ProductColor}30` }}
        required
      />

      <div className="question">{t("Review")}*</div>
      <input
        className="answer-big"
        type="text"
        style={{ backgroundColor: `${ProductColor}30` }}
        name="review_comment"
        required
      />

      <div className="rating">{t("Rating")}</div>
      <div className="select-heart">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`heart ${
              value <= rating ? "heart-filled" : "heart-unfilled"
            }`}
            onClick={() => handleHeartClick(value)}
          >
            {value <= rating ? (
              <IoIosHeart
                style={{ color: ProductColor, height: "54px", width: "54px" }}
              />
            ) : (
              <IoIosHeartEmpty
                style={{ fill: ProductColor, height: "54px", width: "54px" }}
              />
            )}
          </span>
        ))}
      </div>

      <button
        className="single-product-submit"
        type="submit"
        disabled={isLoading}
        style={{ backgroundColor: `${ProductColor}` }}
      >
        {isLoading ? (
          <Oval
            height={50}
            width={50}
            color={ProductColor}
            secondaryColor="grey"
          />
        ) : (
          t("Submit")
        )}
      </button>
      <Snackbar
        open={alertType !== null}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          severity={alertType}
          onClose={handleCloseAlert}
          sx={{ width: "100%", fontSize: "1.5rem" }}
        >
          {alertType === "success" && "Review submitted successfully!"}
          {alertType === "error" &&
            "Failed to submit review. Please try again."}
          {alertType === "warning" &&
            "An error occurred while submitting the form. Please try again."}
        </Alert>
      </Snackbar>
    </form>
  );
}
