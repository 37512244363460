import React, { useState, useEffect } from 'react';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for existing user in local storage (replace with your logic)
    const user = localStorage.getItem('user');
    if (user) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    // Implement your login logic here (e.g., API call, form handling)
    // Update isAuthenticated based on login success
    setIsAuthenticated(true); // Example: Set to true after successful login
  };


  const handleLogout = () => {
    // Implement your specific logout logic here
    localStorage.removeItem('user'); // Example: Remove user data from storage
    setIsAuthenticated(false); // Update authentication state
    window.location.reload();
  };

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    handleLogin,
    handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
