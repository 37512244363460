import React, { createContext, useState, useEffect } from 'react';
import User from 'User';

const CartContext = createContext({
  noProducts: 0,
  addToCart: (productId, quantity) => {},
  updateCartCount: () => {},
  isLoadingCart: false,
  errorFetchingCart: null,
});

export const CartProvider = ({ children }) => {
  const [noProducts, setNoProducts] = useState(0);
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [errorFetchingCart, setErrorFetchingCart] = useState(null);

  useEffect(() => {
    fetchCartCount();
  }, []);

  const fetchCartCount = async () => {
    setIsLoadingCart(true);
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem('guestId');
        if (!guestSessionId) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem('guestId', guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/getTotalNumberOfProductsInCart`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch cart count: ${response.status}`);
      }

      const responseData = await response.json();
      setNoProducts(responseData.total_number_of_products);
    } catch (error) {
      console.error('Error while fetching the cart count:', error);
      setErrorFetchingCart(error.message);
    } finally {
      setIsLoadingCart(false);
    }
  };

  const generateGuestSessionId = () => {
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    return `guest_${randomNumber}`;
  };

  const addToCart = async (productId, quantity) => {
    setIsLoadingCart(true);
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem('guestId');
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem('guestId', guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/addToCart?product_id=${productId}&quantity=${quantity}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to add product to cart: ${response.status}`);
      }

      const responseData = await response.json();
      setNoProducts(responseData.no_of_products);
      localStorage.setItem('noProducts', responseData.no_of_products);
    } catch (error) {
      console.error('Error while adding the product to the cart:', error);
    } finally {
      setIsLoadingCart(false);
    }
  };

  const updateCartCount = () => {
    // Call the fetchCartCount method to update the cart count
    fetchCartCount();
  };

  return (
    <CartContext.Provider
      value={{
        noProducts,
        addToCart,
        updateCartCount,
        isLoadingCart,
        errorFetchingCart,
        fetchCartCount
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
