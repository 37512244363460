import React, { useContext } from "react";
import CartContext from "CartContext";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const AddToCart = ({ buttonColor, product, setLoading }) => {
  const { addToCart } = useContext(CartContext);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
      ReactGA.initialize("G-3KMJ2EVWXY");
      ReactGA.event({
        category: "User",
        action: "Clicked a button",
      });
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };
  // Function to convert English numbers to Arabic
  const convertToArabicNumerals = (number) => {
    if (number == null) return "";
    const englishToArabicMap = {
      0: "٠",
      1: "١",
      2: "٢",
      3: "٣",
      4: "٤",
      5: "٥",
      6: "٦",
      7: "٧",
      8: "٨",
      9: "٩",
    };

    return number
      .toString()
      .split("")
      .map((char) => englishToArabicMap[char] || char)
      .join("");
  };

  const isSoldOut = product.status === "Sold out";

  // Convert prices to Arabic if the language is Arabic
  const price = isArabic
    ? convertToArabicNumerals(product.price)
    : product.price;
  const discountedPrice = isArabic
    ? convertToArabicNumerals(product.discounted_price)
    : product.discounted_price;
  // Hardcoded 304 in Arabic and English
  const displayHardCoddedPrice = isArabic ? "٣٠٤" : "304";

  return (
    <button
      className={`add-to-cart ${isArabic ? "arabic" : ""}`}
      style={{
        background: isSoldOut ? "rgba(196, 192, 192, 1)" : buttonColor,
        cursor: isSoldOut ? "not-allowed" : "pointer",
      }}
      onClick={isSoldOut ? null : (event) => handleAddToCart(product.id, event)}
      disabled={isSoldOut}
    >
      {isSoldOut ? (
        <>{t("out_of_stock")}</>
      ) : product.type === "bundle" ? (
        <>
          {t("add_to_cart")} -{" "}
          <span
            style={{
              marginRight: "7px",
            }}
            className="strikethrough"
          >
            {displayHardCoddedPrice} {t("SAR")}
          </span>
          {price} {t("SAR")}
        </>
      ) : product.status === "Sale" && product.discounted_price ? (
        <>
          {t("add_to_cart")} -{" "}
          <span
            style={{
              marginRight: "7px",
            }}
            className="strikethrough"
          >
            {price} {t("SAR")}
          </span>{" "}
          {discountedPrice} {t("SAR")}
        </>
      ) : (
        <>
          {t("add_to_cart")} - {price} {t("SAR")}
        </>
      )}
    </button>
  );
};

export default AddToCart;
