import React from "react";
import "./privacy.css";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar"; // Detect if the language is Arabic
  return (
    <main>
      <div className="privacy-web">
        <div className={`privacy-container ${isArabic ? "arabic" : ""}`}>
          <div className={`privacy-title ${isArabic ? "arabic" : ""}`}>
            {t("privacTerms-title")}
          </div>

          {/* <span style={{ marginBottom: "20px" }}>
            Welcome to natindamiddleeast.com
          </span> */}

          <span style={{ fontWeight: 500, fontSize: "28px" }}>
            {t("privacTerms-termAndConditionsOfUse")}
          </span>
          <div>
            {!isArabic ? (
              <>
                These terms and conditions ("Terms and Conditions") apply to the
                website{" "}
                <a href="https://natindamiddleeast.com/">
                  https://natindamiddleeast.com/
                </a>
                , ("the Website"), which is operated and managed by Al-Wojooh
                Al- Baraqah Trading Company for Cosmetics and all of its
                divisions, affiliates, and affiliated websites that refer to
                these Terms and Conditions By accessing or using the Website,
                you agree to be bound by all of the Terms and Conditions. If you
                do not agree to these Terms and Conditions, you may not use this
                Website. The Website reserves the right to change, modify, add,
                or remove portions of these Terms and Conditions at any time.
                Changes will be effective when they are posted on the Website
                without further notice. Please review these Terms and Conditions
                regularly for updates. Your continued use of the Website after
                changes to these Terms and Conditions are posted signifies your
                acceptance of these changes.
              </>
            ) : (
              <>
                
                الإلكتروني{" "}
                <a href="https://natindamiddleeast.com/">
                  https://natindamiddleeast.com/
                </a> {" "}
                ("الموقع الإلكتروني") والذي يتم تشغيله وإدارته من قبل شركة
                الوجوه البراقة للتجارة لتوزيع مستحضرات التجميل وجميع أقسامها
                والشركات التابعة لها والمواقع الإلكترونية التابعة المرتبطة بهذه
                الشروط والأحكام. باستخدامك أو دخولك إلى الموقع الإلكتروني، فإنك
                توافق على الالتزام بكافة الشروط والأحكام. إذا كنت لا توافق على
                هذه الشروط والأحكام، فلا يجوز لك استخدام هذا الموقع الإلكتروني.
                يحتفظ الموقع الإلكتروني بالحق في تغيير أو تعديل أو إضافة أو
                إزالة أجزاء من هذه الشروط والأحكام في أي وقت. ستصبح التغييرات
                سارية المفعول عند نشرها على الموقع الإلكتروني دون إشعار. يرجى
                مراجعة هذه الشروط والأحكام بانتظام للاطلاع على التحديثات. إن
                استمرارك في استخدام الموقع الإلكتروني بعد نشر التغييرات على هذه
                الشروط والأحكام يدل على موافقتك على هذه التغييرات.
              </>
            )}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-UseOfTheWebsite")}
          </span>
          <div>{t("privacTerms-UseOfTheWebsite-text")}</div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-UserSubmission")}
          </span>
          <div>
            {!isArabic ? (
              <>
                Anything that you submit to the Website and/or provide to us,
                including but not limited to questions, reviews, comments, and
                suggestions (collectively, "Submissions"), will become our sole
                and exclusive property and will not be returned to you. In
                addition to the rights applied to any Submission, when you post
                comments or reviews on the Website, you also grant us the right
                to use the name you submit, in connection with such review or
                comment or any other Content. You may not use a false email
                address, pretend to be someone other than you are, or otherwise
                mislead us or third parties as to the origin of any Submissions.
                We may, but are not obligated to, remove or edit any
                Submissions.
              </>
            ) : (
              <>
                أي شيء تقوم بإرساله إلى الموقع الإلكتروني أو تقدمه لنا، بما في
                ذلك على سبيل المثال لا الحصر الأسئلة والمراجعات والتعليقات
                والاقتراحات, ستصبح ملكيتنا الحصرية والكاملة ولن يتم إرجاعها
                إليك. بالإضافة إلى الحقوق المطبقة على أي مساهمة، عندما تقوم بنشر
                التعليقات أو المراجعات على الموقع الإلكتروني، فإنك تمنحنا أيضًا
                الحق في استخدام الاسم الذي تقدمه، فيما يتعلق بتلك المراجعة أو
                التعليق أو أي محتوى آخر. لا يجوز لك استخدام عنوان بريد إلكتروني
                كاذب أو التظاهر بأنك شخص آخر غيرك، أو تضليلنا بطريقة أو بأخرى
                بشأن مصدر مساهماتك. ويجوز لنا، ولكننا لسنا ملزمين، إزالة أو
                تعديل أي إسهامات تقوم بها في إطار سياساتنا.
              </>
            )}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-AccuracyOfInfo")}
          </span>
          <div>{t("privacTerms-AccuracyOfInfo-text")}</div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-OrderAcceptancePricing")}
          </span>
          <div>{t("privacTerms-OrderAcceptancePricing-text")}</div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-Delivery")}
          </span>
          <div>{t("privacTerms-Delivery-text")}</div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-Payment")}
          </span>
          <div>{t("privacTerms-Payment-text")}</div>

          <span
            style={{ fontWeight: 500, marginTop: "40px", fontSize: "28px" }}
          >
            {t("privacTerms-ReturnAndExchanges")}
          </span>

          <div>
            {t("privacTerms-ReturnAndExchanges-text1")}
            <br />
            {t("privacTerms-ReturnAndExchanges-text2")}
            <br />
            {t("privacTerms-ReturnAndExchanges-text3")}
          </div>
        </div>
      </div>
      <div className="privacy-mob">
        <div className={`privacy-container-mob ${isArabic ? "arabic" : ""}`}>
          <div className={`privacy-title ${isArabic ? "arabic" : ""}`}>{t("privacTerms-title")}</div>
          {/* <span style={{ marginBottom: "30px" }}>
            Welcome to natindamiddleeast.com
          </span> */}
          <span style={{ fontWeight: 500, fontSize: "24px" }}>
          {t("privacTerms-termAndConditionsOfUse")}
          </span>
          <div>
          {!isArabic ? (
              <>
                These terms and conditions ("Terms and Conditions") apply to the
                website{" "}
                <a href="https://natindamiddleeast.com/">
                  https://natindamiddleeast.com/
                </a>
                , ("the Website"), which is operated and managed by Al-Wojooh
                Al- Baraqah Trading Company for Cosmetics and all of its
                divisions, affiliates, and affiliated websites that refer to
                these Terms and Conditions By accessing or using the Website,
                you agree to be bound by all of the Terms and Conditions. If you
                do not agree to these Terms and Conditions, you may not use this
                Website. The Website reserves the right to change, modify, add,
                or remove portions of these Terms and Conditions at any time.
                Changes will be effective when they are posted on the Website
                without further notice. Please review these Terms and Conditions
                regularly for updates. Your continued use of the Website after
                changes to these Terms and Conditions are posted signifies your
                acceptance of these changes.
              </>
            ) : (
              <>
                
                الإلكتروني{" "}
                <a href="https://natindamiddleeast.com/">
                  https://natindamiddleeast.com/
                </a> {" "}
                ("الموقع الإلكتروني") والذي يتم تشغيله وإدارته من قبل شركة
                الوجوه البراقة للتجارة لتوزيع مستحضرات التجميل وجميع أقسامها
                والشركات التابعة لها والمواقع الإلكترونية التابعة المرتبطة بهذه
                الشروط والأحكام. باستخدامك أو دخولك إلى الموقع الإلكتروني، فإنك
                توافق على الالتزام بكافة الشروط والأحكام. إذا كنت لا توافق على
                هذه الشروط والأحكام، فلا يجوز لك استخدام هذا الموقع الإلكتروني.
                يحتفظ الموقع الإلكتروني بالحق في تغيير أو تعديل أو إضافة أو
                إزالة أجزاء من هذه الشروط والأحكام في أي وقت. ستصبح التغييرات
                سارية المفعول عند نشرها على الموقع الإلكتروني دون إشعار. يرجى
                مراجعة هذه الشروط والأحكام بانتظام للاطلاع على التحديثات. إن
                استمرارك في استخدام الموقع الإلكتروني بعد نشر التغييرات على هذه
                الشروط والأحكام يدل على موافقتك على هذه التغييرات.
              </>
            )}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-UseOfTheWebsite")}
          </span>
          <div>
          {t("privacTerms-UseOfTheWebsite-text")}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-UserSubmission")}
          </span>
          <div>
            {!isArabic ? (
              <>
                Anything that you submit to the Website and/or provide to us,
                including but not limited to questions, reviews, comments, and
                suggestions (collectively, "Submissions"), will become our sole
                and exclusive property and will not be returned to you. In
                addition to the rights applied to any Submission, when you post
                comments or reviews on the Website, you also grant us the right
                to use the name you submit, in connection with such review or
                comment or any other Content. You may not use a false email
                address, pretend to be someone other than you are, or otherwise
                mislead us or third parties as to the origin of any Submissions.
                We may, but are not obligated to, remove or edit any
                Submissions.
              </>
            ) : (
              <>
                أي شيء تقوم بإرساله إلى الموقع الإلكتروني أو تقدمه لنا، بما في
                ذلك على سبيل المثال لا الحصر الأسئلة والمراجعات والتعليقات
                والاقتراحات, ستصبح ملكيتنا الحصرية والكاملة ولن يتم إرجاعها
                إليك. بالإضافة إلى الحقوق المطبقة على أي مساهمة، عندما تقوم بنشر
                التعليقات أو المراجعات على الموقع الإلكتروني، فإنك تمنحنا أيضًا
                الحق في استخدام الاسم الذي تقدمه، فيما يتعلق بتلك المراجعة أو
                التعليق أو أي محتوى آخر. لا يجوز لك استخدام عنوان بريد إلكتروني
                كاذب أو التظاهر بأنك شخص آخر غيرك، أو تضليلنا بطريقة أو بأخرى
                بشأن مصدر مساهماتك. ويجوز لنا، ولكننا لسنا ملزمين، إزالة أو
                تعديل أي إسهامات تقوم بها في إطار سياساتنا.
              </>
            )}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-AccuracyOfInfo")}
          </span>
          <div>
          {t("privacTerms-AccuracyOfInfo-text")}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-OrderAcceptancePricing")}
          </span>
          <div>
          {t("privacTerms-OrderAcceptancePricing-text")}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-Delivery")}
          </span>
          <div>
          {t("privacTerms-Delivery-text")}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-Payment")}
          </span>
          <div>{t("privacTerms-Payment-text")}
          </div>

          <span
            style={{ fontWeight: 500, marginTop: "30px", fontSize: "24px" }}
          >
            {t("privacTerms-ReturnAndExchanges")}
          </span>

          <div>
            {t("privacTerms-ReturnAndExchanges-text1")}
            <br />
            {t("privacTerms-ReturnAndExchanges-text2")}
            <br />
            {t("privacTerms-ReturnAndExchanges-text3")}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Privacy;
