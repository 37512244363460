import { Link } from "react-router-dom";
import HeartIcon from "./HeartIcon";
import AddToCart from "./addToCart";
import { useTranslation } from "react-i18next";
import Arrow from "../assets/arrow.svg";

const ProductSkin = ({ product, setLoading, ingredients, text }) => {
  console.log("product", product);
  const heartColor = "#FCDBCC";
  const averageRating = product.avg_rating < 1 ? 3 : product.avg_rating;

  const saleFlag =
    product.status === "Sale" && product.discounted_price ? true : false;

  const soldOutFlag = product.status === "Sold out" ? true : false;
  const bundleFlag = product.type === "bundle" ? true : false;

  const { t, i18n } = useTranslation();

  return (
    <Link
      to={`/product/${encodeURIComponent(
        product.name_en.replace(/\s+/g, "-").toLowerCase()
      )}/${product.id}`}
    >
      <div className="product-skin">
        <div className="product-skin">
          {saleFlag && <div className="sale-badge">{t("Sale")}</div>}
          {soldOutFlag && <div className="sale-badge">{t("out_of_stock")}</div>}
          {bundleFlag && <div className="sale-badge">{t("sale_50")}</div>}
          <img src={product.medias[0].image_url} alt={product.tag} />
        </div>
        <div className="hearts-container">
          {Array.from({ length: Math.round(averageRating) }, (_, index) => (
            <HeartIcon
              key={index}
              color={heartColor}
              width={25}
              height={22}
              alt={`Heart ${index + 1}`}
            />
          ))}
        </div>
        <div className="product-skin-name">
          {" "}
          {product[`name_${i18n.language.slice(-2)}`]}{" "}
        </div>
        <div className="product-skin-row">
          {ingredients.map((ingredient, index) => (
            <span className="product-skin-light" key={index}>
              <img
                src={Arrow}
                alt="Arrow"
                style={{ height: "22px", width: "22px" }}
              />{" "}
              {ingredient}
            </span>
          ))}
        </div>
        <AddToCart
          buttonColor={product.theme}
          product={product}
          setLoading={setLoading}
        />
        {text && <span className="product-skin-light"> {text}</span>}
      </div>
    </Link>
  );
};

export default ProductSkin;
