import React from 'react'
import LazyLoad from 'react-lazyload';

import SherbatVector from "../assets/Calming-Sherbat-Vector.svg";
import image from "../assets/33.png";


import GoldVector from "../assets/GOLD-VECTORSTROKE.svg";
import GoldImage from "../assets/Atticos.png";


import calmingBlueImage from "../assets/calming-blue-Image.png";
import calmingBlueVector from "../assets/CALMING FOAM.svg";

import PinkImage from "../assets/pink-Image.png";
import PinkVector from "../assets/pink-Vector.svg";


import YellowImage from "../assets/MAIN UV.png";
import YellowVector from "../assets/yellow-vector.svg";

import MultiStickImage from "../assets/stick.png";
import MultiStickVector from "../assets/multiStickCare-Blue-Vector.svg";


import "../ProductImages/productImages.css"

const productImages = ({id}) => {
    if(id===8){
    return (
        <div className="image-stack">
        <img src={SherbatVector} alt="Layer 2" className="layer" />
        <img src={image} alt="Layer 3" className="enlarged" />
    </div>
    )
    }
    else if(id===11){
        return(
        <div className="image-stack2">
        <img src={GoldVector} alt="Layer 2" className="layer2" />
        <img src={GoldImage} alt="Layer 3" className="enlarged2" />
    </div>
        )
    }else if(id===10){
        return(
            <div className="image-stack3">
           <img src={calmingBlueVector} alt="Layer 2" className="layer3" />
            <img src={calmingBlueImage} alt="Layer 3" className="enlarged3" />
        </div>
            )
    }else if(id===6){
        return(
            <div className="image-stack4">
           <img src={PinkVector} alt="Layer 2" className="layer4" />
            <img src={PinkImage} alt="Layer 3" className="enlarged4" />
        </div>
            )
    }else if(id===9){
        return(
            <div className="image-stack5">
           <img src={YellowVector} alt="Layer 2" className="layer5" />
            <img src={YellowImage} alt="Layer 3" className="enlarged5" />
        </div>
            )
    }
    else if(id===7){
        return(
            <div className="image-stack6">
           <img src={MultiStickVector} alt="Layer 2" className="layer6" />
            <img src={MultiStickImage} alt="Layer 3" className="enlarged6" />
        </div>
            )
    }
}

export default productImages