import React, { useState, useEffect } from "react";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./profile.css";
import User from "User";
import { SyncLoader } from "react-spinners";
import AuthContext from "AuthContext";
import { useContext } from "react";
import Error from "./../../assets/error.png";
import "./../../fonts/fonts.css";
import CartContext from "CartContext";
import RightArrow from "./../../assets/right-md.svg";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState(null);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [errorMsg, setErrorMsg] = useState(null);
  const [products, setProducts] = useState(null);
  const [activeSection, setActiveSection] = useState("account");
  const [isEditMode, setIsEditMode] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newBirthday, setNewBirthday] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const buttonColor = "#FDDCCD";
  const { addToCart } = useContext(CartContext);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = User.fromLocalStorage();
        if (!user || !user.api_token) {
          setErrorMsg("User data not found or missing API token.");
          return;
        }

        const response = await fetch(
          "https://dashboard.natindamiddleeast.com/api/userProfile",
          {
            headers: {
              Authorization: `Bearer ${user.api_token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }

        const responseData = await response.json();

        setUser({
          ...user,
          first_name: responseData.user.first_name,
          last_name: responseData.user.last_name,
          api_token: responseData.user.api_token,
          points: responseData.user.points,
          accept_promotion: responseData.user.accept_promotion,
          wishlist: responseData.user.wishlist,
        });
        setOrders(responseData.user.orders);
        const products = responseData.products;
        setNewEmail(responseData.user.email);
        setNewFirstName(responseData.user.first_name);
        setNewLastName(responseData.user.last_name);
        setNewBirthday(responseData.user.birthday);

        setProducts(products);
        if (user && user.shippingaddresses.length > 0) {
          setNewAddress(user.shippingaddresses[0].address);
          setNewCity(user.shippingaddresses[0].city);
          setNewCountry(user.shippingaddresses[0].country);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setErrorMsg(`Error fetching user data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const logout = () => {
    localStorage.removeItem("user");
    setUser(null);
    setIsAuthenticated(false); // Update authentication state in context
    navigate("/", { replace: true }); // Redirect to login after logout
  };

  const NavProducts = () => {
    navigate("/products");
  };

  const NavOrderDetails = (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    setActiveSection("single-order");
    setSelectedOrder(order);
    console.log(order);
  };

  const handleRemoveFromWishlist = async (productId, event) => {
    setLoading(true);
    try {
      event.preventDefault();

      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/removeFromWishlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.api_token}`,
          },
          body: JSON.stringify({ product_id: productId }),
        }
      );

      if (response.ok) {
        const updatedWishlist = user.wishlist.filter(
          (item) => item.product_id !== productId
        );
        setUser({ ...user, wishlist: updatedWishlist });
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, wishlist: updatedWishlist })
        );

        if (products) {
          const updatedProducts = products.filter(
            (product) => product.id !== productId
          );
          setProducts(updatedProducts);
        }
      } else {
        console.error(
          "Failed to remove product from wishlist:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
      // Handle the error, e.g., display an error message
    } finally {
      setLoading(false);
    }
  };
  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleEditMode = () => {
    if (isEditMode) {
      // Attempt to save changes when in edit mode
      handleUpdateUserProfile();
    }
    setIsEditMode(!isEditMode);
  };
  const handleUpdateUserProfile = async () => {
    setLoading(true);

    const updatedData = {};
    if (newEmail.trim() && newEmail !== user.email)
      updatedData.email = newEmail;
    if (newFirstName.trim() && newFirstName !== user.first_name)
      updatedData.first_name = newFirstName;
    if (newLastName.trim() && newLastName !== user.last_name)
      updatedData.last_name = newLastName;
    if (newBirthday.trim() && newBirthday !== user.birthday)
      updatedData.birthday = newBirthday;

    // Prepare the shipping address update
    const shippingAddressUpdates =
      user.shippingaddresses.length > 0
        ? { id: user.shippingaddresses[0].id }
        : {};

    const defaultAddress =
      user.shippingaddresses.length > 0 ? user.shippingaddresses[0] : null;
    if (defaultAddress) {
      if (newAddress !== defaultAddress.address)
        shippingAddressUpdates.address = newAddress;
      if (newCity !== defaultAddress.city)
        shippingAddressUpdates.city = newCity;
      if (newCountry !== defaultAddress.country)
        shippingAddressUpdates.country = newCountry;
    }

    if (Object.keys(shippingAddressUpdates).length > 1) {
      updatedData.shipping_address = shippingAddressUpdates;
    }

    // Include old and new password in the update if provided
    if (oldPassword && newPassword) {
      updatedData.oldpassword = oldPassword;
      updatedData.newpassword = newPassword;
    }

    if (Object.keys(updatedData).length === 0) {
      setLoading(false);
      setIsEditMode(false);
      return;
    }

    try {
      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/updateUserProfile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.api_token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) throw new Error("Failed to update profile.");

      const responseData = await response.json();

      setUser(new User(responseData.user));
      localStorage.setItem("user", JSON.stringify(responseData.user));
    } catch (error) {
      console.error("Error updating profile:", error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
      setIsEditMode(false);
    }
  };
  return (
    <main>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : errorMsg ? (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text">{t("unexpected_error")}</div>
        </div>
      ) : user ? (
        <>
          {/* web view */}
          <div className="web-view-profile">
            <div className="profile-big-column">
              <div></div>
              {/* name row */}
              <div className="row">
                <div className="profile-name">
                  {t("HI")} {user.first_name}
                </div>
                {activeSection === "account" && (
                  <div className="profile-edit">
                    <button onClick={toggleEditMode}>
                      {isEditMode ? t("save") : t("edit")}
                    </button>
                  </div>
                )}

                {activeSection === "single-order" && (
                  <div className="profile-edit">
                    <button onClick={() => setActiveSection("orders")}>
                      {t("back_to_orders")}
                    </button>
                  </div>
                )}
              </div>
              {/* big row */}
              <div className="row">
                {/* buttons div */}
                <div className="profile-left-button">
                  <Link onClick={() => setActiveSection("account")}>
                    <div
                      className={
                        activeSection === "account"
                          ? "active-profile-box"
                          : "profile-box"
                      }
                    >
                      {t("Account")}
                    </div>
                  </Link>

                  <Link onClick={() => setActiveSection("orders")}>
                    <div
                      className={
                        activeSection === "orders"
                          ? "active-profile-box"
                          : "profile-box"
                      }
                    >
                      {t("Orders")}
                    </div>
                  </Link>

                  <Link onClick={() => setActiveSection("wishlist")}>
                    <div
                      className={
                        activeSection === "wishlist"
                          ? "active-profile-box"
                          : "profile-box"
                      }
                    >
                      {t("Wishlist")}
                    </div>
                  </Link>

                  <Link to="/rewards">
                    <div
                      className={
                        activeSection === "rewards"
                          ? "active-profile-box"
                          : "profile-box"
                      }
                    >
                      {t("Rewards")}
                    </div>
                  </Link>
                </div>
                {/* account active session */}
                {activeSection === "account" && (
                  <div className="profile-account-column">
                    <div className="profile-column">
                      {/* change email */}
                      <div className="profile-single-column">
                        <span>
                          {" "}
                          {user.first_name} {user.last_name}
                        </span>
                        {isEditMode ? (
                          <input
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                          />
                        ) : (
                          <div className="profile--single-column-details">
                            {user.email}
                          </div>
                        )}
                      </div>
                      <hr
                        style={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          height: "1px",
                          margin: "0",
                        }}
                      />
                      {/* change address */}
                      <div className="profile-single-column">
                        <span>{t("default_address")}</span>
                        {isEditMode ? (
                          <>
                            <input
                              type="text"
                              value={newAddress}
                              onChange={(e) => setNewAddress(e.target.value)}
                              placeholder="Address"
                            />
                            <input
                              type="text"
                              value={newCity}
                              onChange={(e) => setNewCity(e.target.value)}
                              placeholder="City"
                            />
                            <input
                              type="text"
                              value={newCountry}
                              onChange={(e) => setNewCountry(e.target.value)}
                              placeholder="Country"
                            />
                          </>
                        ) : (
                          <div>
                            {user.shippingaddresses.length > 0 ? (
                              <>
                                {user.shippingaddresses[0].address},{" "}
                                {user.shippingaddresses[0].city},{" "}
                                {user.shippingaddresses[0].country}
                              </>
                            ) : (
                              <> {t("no_address")}</>
                            )}
                          </div>
                        )}
                      </div>
                      <hr
                        style={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          height: "1px",
                          margin: "0",
                        }}
                      />
                      {/* change Password */}
                      <div className="profile-single-column">
                        <span>{t("Password")}</span>
                        {isEditMode ? (
                          <div className="password-inputs">
                            <input
                              className="profile-edit-input"
                              type="password"
                              placeholder={t("old_password")}
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <input
                              className="profile-edit-input"
                              type="password"
                              placeholder={t("new_password")}
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                        ) : (
                          <div className="profile--single-column-details">
                            ************
                          </div>
                        )}
                      </div>

                      <hr
                        style={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          height: "1px",
                          margin: "0",
                        }}
                      />
                      {/* change Birthday */}
                      <div className="profile-single-column">
                        <span>{t("Birthday")}</span>
                        {isEditMode ? (
                          <input
                            type="date"
                            value={newBirthday}
                            onChange={(e) => setNewBirthday(e.target.value)}
                          />
                        ) : (
                          <div className="profile--single-column-details">
                            {user.birthday}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="profile-redeem-points">
                      {t("you_have_points", {
                        points: user.points === null ? "0" : user.points * 10,
                      })}
                    </div>

                    <div className="profile-rewards-buttons">
                      <button
                        className="rewards-history-button"
                        onClick={NavProducts}
                      >
                        {t("rewards_history")}
                      </button>
                      <button
                        className="redeem-points-button"
                        onClick={NavProducts}
                      >
                        {t("redeem_now")}
                      </button>
                    </div>
                  </div>
                )}
                {/* wishlist active session */}
                {activeSection === "wishlist" && (
                  <div className="profile-wishlist">
                    {products && products.length > 0 ? (
                      products.map((product, index) => (
                        <div key={product.id} className="wishlist-item">
                          <Link
                            to={`/product/${encodeURIComponent(
                              product.name_en.replace(/\s+/g, "-").toLowerCase()
                            )}/${product.id}`}
                          >
                            <div className="wishlist-product">
                              {/* Display thumbnail image */}

                              {product.status === "Sale" &&
                                product.discounted_price && (
                                  <div className="sale-badge">{t("Sale")}</div>
                                )}

                              {product.status === "Sold out" && (
                                <div className="sale-badge">
                                  {t("out_of_stock")}
                                </div>
                              )}
                              {product.medias && product.medias.length > 0 && (
                                <img
                                  src={
                                    product.medias.find(
                                      (media) => media.tag === "thumbnail"
                                    ).image_url
                                  }
                                  alt={product.tag}
                                />
                              )}

                              {/* Product name */}
                              <div className="wishlist-products-name">
                                {product[`name_${i18n.language.slice(-2)}`]}{" "}
                              </div>

                              {/* Add to Cart button */}
                              <button
                                className="add-to-cart"
                                style={{
                                  background:
                                    product.status === "Sold out"
                                      ? "#D3D3D3"
                                      : buttonColor,
                                  cursor:
                                    product.status === "Sold out"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={
                                  product.status === "Sold out"
                                    ? null
                                    : (event) =>
                                        handleAddToCart(product.id, event)
                                }
                                disabled={product.status === "Sold out"}
                              >
                                {product.status === "Sold out" ? (
                                  <>{t("out_of_stock")}</>
                                ) : product.type === "bundle" ? (
                                  <>
                                    {t("add_to_cart")} -{" "}
                                    <span
                                      style={{
                                        marginRight: "7px",
                                      }}
                                      className="strikethrough"
                                    >
                                      304 {t("SAR")}
                                    </span>
                                    {product.price} {t("SAR")}
                                  </>
                                ) : product.status === "Sale" &&
                                  product.discounted_price ? (
                                  <>
                                    {t("add_to_cart")} -{" "}
                                    <span
                                      style={{
                                        marginRight: "7px",
                                      }}
                                      className="strikethrough"
                                    >
                                      {product.price} {t("SAR")}
                                    </span>
                                    {product.discounted_price} {t("SAR")}
                                  </>
                                ) : (
                                  <>
                                    {t("add_to_cart")} - {product.price}{" "}
                                    {t("SAR")}
                                  </>
                                )}
                              </button>

                              {/* Remove button */}
                              <div
                                className="wishlist-remove"
                                onClick={(event) =>
                                  handleRemoveFromWishlist(product.id, event)
                                }
                              >
                                {t("remove")}
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <div className="no-wishlist-items">
                        {t("wishlist_empty")}
                      </div>
                    )}
                  </div>
                )}
                {/* Orders active session */}
                {activeSection === "orders" && (
                  <>
                    {orders && orders.length > 0 ? (
                      <div className="profile-column">
                        {orders.map((order, index) => (
                          <div className="profile-single-order" key={index}>
                            <div className="profile-single-first-order">
                              {order.products[0].medias.find(
                                (media) => media.tag === "square"
                              ) && (
                                <img
                                  src={
                                    order.products[0].medias.find(
                                      (media) => media.tag === "square"
                                    ).image_url
                                  }
                                  alt="square"
                                />
                              )}
                              <div className="profile-single-order-column">
                                <div>
                                  {order.products.map((product, index) => (
                                    <span key={index}>
                                      {i18n.language === "ar"
                                        ? product.name_ar.length > 20
                                          ? product.name_ar.substring(0, 20) +
                                            "..."
                                          : product.name_ar
                                        : product.name_en.length > 20
                                        ? product.name_en.substring(0, 20) +
                                          "..."
                                        : product.name_en}
                                      {index !== order.products.length - 1 &&
                                        ", "}
                                    </span>
                                  ))}
                                </div>
                                <span style={{ fontWeight: 500 }}>
                                  {order.total_price} {t("SAR")}
                                </span>
                              </div>
                            </div>

                            <div className="profile-single-second-order">
                              <div>
                                <img
                                  onClick={() => NavOrderDetails(order.id)}
                                  src={RightArrow}
                                  alt="RightArrow"
                                />
                              </div>
                              {t("order_id")} #{order.id}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-wishlist-items">
                        {t("no_orders_yet")}
                      </div>
                    )}
                  </>
                )}

                {/* Single Order active session */}
                {activeSection === "single-order" && selectedOrder && (
                  <>
                    <div
                      className="profile-column"
                      style={{ padding: "20px", gap: "20px" }}
                    >
                      {selectedOrder.products.map((product, index) => (
                        <div
                          style={{ borderBottom: "none" }}
                          className="profile-single-order-details"
                          key={index}
                        >
                          <img
                            style={{ width: "102px" }}
                            src={product.medias[0].image_url}
                            alt={product.medias[0].tag}
                          />
                          <div>
                            {" "}
                            {product[`name_${i18n.language.slice(-2)}`]}{" "}
                          </div>
                        </div>
                      ))}
                      <div className="profile-single-order-details-column">
                        <div style={{ fontWeight: "500" }}>
                          {selectedOrder.total_price} SAR
                        </div>
                        <div>
                          {t("order_id")} #{selectedOrder.id}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Mobile view */}
          <div className="mobile-view-profile">
            {/* name and logut  */}
            <div className="profile-mobile-row">
              <div className="profile-name">Hi {user.first_name}</div>
              <div className="profile-logout" onClick={logout}>
                {t("log_out")}
              </div>
            </div>
            {/* buttons  */}
            <div className="profile-left-button">
              <Link onClick={() => setActiveSection("account")}>
                <div
                  className={
                    activeSection === "account"
                      ? "active-profile-box"
                      : "profile-box"
                  }
                >
                  {t("Account")}
                </div>
              </Link>

              <Link onClick={() => setActiveSection("orders")}>
                <div
                  className={
                    activeSection === "orders"
                      ? "active-profile-box"
                      : "profile-box"
                  }
                >
                  {t("Orders")}
                </div>
              </Link>

              <Link onClick={() => setActiveSection("wishlist")}>
                <div
                  className={
                    activeSection === "wishlist"
                      ? "active-profile-box"
                      : "profile-box"
                  }
                >
                  {t("Wishlist")}
                </div>
              </Link>

              <Link to="/rewards">
                <div
                  className={
                    activeSection === "rewards"
                      ? "active-profile-box"
                      : "profile-box"
                  }
                >
                  {t("Rewards")}
                </div>
              </Link>
            </div>

            {/* account active session */}
            {activeSection === "account" && (
              <div className="profile-account-column">
                <div className="profile-column">
                  {/* change name */}
                  <div className="profile-single-column">
                    <div className="profile-inner-row">
                      <div className="profile-inner-column">
                        {user.first_name} {user.last_name}
                      </div>
                      <div className="profile-column-underline">
                        {t("Change")}
                      </div>
                    </div>
                    <div className="profile-column-sub">{user.email}</div>
                  </div>
                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      height: "1px",
                      margin: "0",
                    }}
                  />
                  {/* change address */}
                  <div className="profile-single-column">
                    <div className="profile-inner-row">
                      <div className="profile-inner-column">
                        {t("default_address")}
                      </div>
                      <div className="profile-column-underline">
                        {t("Change")}
                      </div>
                    </div>
                    <div className="profile-column-sub">
                      {user.shippingaddresses.length > 0 ? (
                        <>
                          {user.shippingaddresses[0].address},{" "}
                          {user.shippingaddresses[0].city},{" "}
                          {user.shippingaddresses[0].country}
                        </>
                      ) : (
                        <> {t("no_address")}</>
                      )}
                    </div>
                  </div>
                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      height: "1px",
                      margin: "0",
                    }}
                  />
                  {/* change Password */}
                  <div className="profile-single-column">
                    <div className="profile-inner-row">
                      <div className="profile-inner-column">
                        {t("Password")}
                      </div>
                      <div className="profile-column-underline">
                        {t("Change")}
                      </div>
                    </div>
                    <div className="profile-column-sub">************</div>
                  </div>

                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      height: "1px",
                      margin: "0",
                    }}
                  />
                  {/* change Birthday */}
                  <div className="profile-single-column">
                    <div className="profile-inner-row">
                      <div className="profile-inner-column">
                        {t("Birthday")}
                      </div>
                      <div className="profile-column-underline">
                        {" "}
                        {t("Change")}
                      </div>
                    </div>
                    <div className="profile-column-sub">{user.birthday}</div>
                  </div>
                </div>

                <div className="profile-redeem-points">
                  {t("you_have_points", {
                    points: user.points === null ? "0" : user.points * 10,
                  })}
                </div>
                <div className="profile-rewards-buttons">
                  <button
                    className="rewards-history-button"
                    onClick={NavProducts}
                  >
                    {t("rewards_history")}
                  </button>
                  <button
                    className="redeem-points-button"
                    onClick={NavProducts}
                  >
                    {t("redeem_now")}
                  </button>
                </div>
              </div>
            )}
            {/* wishlist active session */}
            {activeSection === "wishlist" && (
              <div className="profile-wishlist-mobile">
                {products && products.length > 0 ? (
                  products.map((product, index) => (
                    <Link
                      key={product.id}
                      to={`/product/${encodeURIComponent(
                        product.name_en.replace(/\s+/g, "-").toLowerCase()
                      )}/${product.id}`}
                    >
                      <div className="wishlist-product">
                        <img
                          src={product.medias[0].image_url}
                          alt={product.tag}
                        />
                        <div className="wishlist-mobile-inner-column">
                          <div className="wishlist-products-name">
                            {product[`name_${i18n.language.slice(-2)}`]}{" "}
                          </div>
                          <div className="wishlist-price">
                            {product.type === "bundle" ? (
                              <>
                                <span
                                  style={{
                                    marginRight: "7px",
                                  }}
                                  class="strikethrough"
                                >
                                  304 {t("SAR")}
                                </span>
                                {product.price} {t("SAR")}
                              </>
                            ) : product.status === "Sale" &&
                              product.discounted_price ? (
                              <>
                                <span
                                  style={{
                                    marginRight: "7px",
                                  }}
                                  class="strikethrough"
                                >
                                  {product.price} {t("SAR")}
                                </span>
                                {product.discounted_price} {t("SAR")}
                              </>
                            ) : (
                              <>
                                {product.price} {t("SAR")}
                              </>
                            )}
                          </div>
                          <button
                            className="add-to-basket"
                            onClick={(event) =>
                              handleAddToCart(product.id, event)
                            }
                          >
                            {t("add_to_basket")}
                          </button>
                        </div>

                        <div
                          className="wishlist-remove"
                          onClick={(event) =>
                            handleRemoveFromWishlist(product.id, event)
                          }
                        >
                          {t("remove")}
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className="no-wishlist-items">{t("wishlist_empty")}</div>
                )}
              </div>
            )}
            {/* Orders active session */}
            {activeSection === "orders" && (
              <>
                {orders && orders.length > 0 ? (
                  <div className="profile-column">
                    {orders.map((order, index) => (
                      <div className="profile-single-order" key={index}>
                        <div className="profile-single-first-order">
                          {order.products[0].medias.find(
                            (media) => media.tag === "square"
                          ) && (
                            <img
                              src={
                                order.products[0].medias.find(
                                  (media) => media.tag === "square"
                                ).image_url
                              }
                              alt="square"
                            />
                          )}
                          <div className="profile-single-order-column">
                            <div>
                              {order.products.map((product, index) => (
                                <span key={index}>
                                  {product.name_en.length > 20
                                    ? product.name_en.substring(0, 20) + "..."
                                    : product.name_en}
                                  {index !== order.products.length - 1 && ", "}
                                </span>
                              ))}
                            </div>
                            <span style={{ fontWeight: 500 }}>
                              {" "}
                              {order.total_price} SAR
                            </span>
                          </div>
                        </div>

                        <div className="profile-single-second-order">
                          <div>
                            <img
                              onClick={() => NavOrderDetails(order.id)}
                              src={RightArrow}
                              alt="RightArrow"
                            />
                          </div>
                          Order ID #{order.id}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="no-wishlist-items"
                    style={{ marginTop: "20px" }}
                  >
                    You haven’t placed any orders yet
                  </div>
                )}
              </>
            )}

            {/* Single Order active session */}
            {activeSection === "single-order" && selectedOrder && (
              <>
                <div
                  className="profile-column"
                  style={{ padding: "10px", gap: "10px" }}
                >
                  {selectedOrder.products.map((product, index) => (
                    <div
                      style={{ borderBottom: "none" }}
                      className="profile-single-order-details"
                      key={index}
                    >
                      <img
                        style={{ width: "70px" }}
                        src={product.medias[0].image_url}
                        alt={product.medias[0].tag}
                      />
                      <div> {product[`name_${i18n.language.slice(-2)}`]} </div>
                    </div>
                  ))}
                  <div className="profile-single-order-details-column">
                    <div style={{ fontWeight: "500" }}>
                      {selectedOrder.total_price} {t("SAR")}
                    </div>
                    <div>
                      {" "}
                      {t("order_id")} #{selectedOrder.id}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text"> {t("unexpected_error")}</div>
        </div>
      )}
      <Footer />
    </main>
  );
};
export default Profile;
