import React, { useEffect, useContext } from "react";
import "../../styles/orderSuccess.css";
import { FaCheckCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";

const OrderSuccess = () => {
  const { order_id } = useParams();
  const { fetchCartCount } = useContext(CartContext);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCartCount();
  }, [order_id]);

  return (
    <>
      <div className="orderConfirmation-container">
        <div className="orderConfirmation-pc-body">
          <FaCheckCircle className="orderConfirmation-corect-sign" />
          <div className="order-Confirmed-title">
            {t("Order_Confirmed_Title")}
          </div>
          <div className="order-Confirmed-text-body">
            {t("Order_Confirmed_Text_Body")}
          </div>
          <Link to="/products">
            <div className="continue-shopping-button-OC">
              {t("Continue_Shopping_Button")}
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
