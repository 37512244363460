// TermsAndConditions.js
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './terms.css'; // Make sure this path is correct
import Footer from 'components/Footer';

const termsData = [
  {
    question: "What is Natinda’s VIP Squad?",
    answer: "Shop with Natinda and get rewarded! Our VIP Squad is free to join and lets you earn points for every purchase and other activities. Sign up to be a member of our quad and enjoy perks like exclusive discounts, early access to sales, and many more. Redeem your points for exciting rewards on our website."
  },
  {
    question: "How do I join the VIP Squad?",
    answer: "You can click here to get started. You will then be redirected to create an account or log in to your Natinda’s account."
  },
  {
    question: "Are there any fees for joining?",
    answer: "We would love to have you in our squad 100% for free without any fees."
  },
  {
    question: "Can I join the VIP Squad if I am shopping out of KSA?",
    answer: "Unfortunately, at the moment joining the VIP Squad is only restricted to Saudi Arabia. However, we will be announcing updates on expansion."
  },
  {
    question: "Can I earn points if I did not join the VIP Squad?",
    answer: "Earning points is very simple, you just need to create an account on Natinda’s website."
  },
  {
    question: "How do I earn the points?",
    answer: "You earn points on every purchase you make on Natinda’s website. For every 1 SAR you spend you earn 1 point. You also earn points by completing tasks that you’ll find on Natinda’s VIP Squad Page."
  },
  {
    question: "Why didn’t I earn points when I left a review?",
    answer: "Reviews need to be verified. This means that you need to leave a review through your verified account to receive your eligible points."
  },
  {
    question: "How do I redeem my points?",
    answer: "You can redeem your points during checkout."
  },
  {
    question: "How do I check the points in my balance?",
    answer: "Once you log in to your account you can view your points balance from my account page."
  },
  {
    question: "When are the new points credited to my account?",
    answer: "Your points will be instantly added to your account after every qualifying purchase or action."
  },
  {
    question: "Do my points expire?",
    answer: "Your points expire after 12 months of inactivity. Make sure to use them for awesome rewards before they disappear!"
  },
  {
    question: "Could I redeem points while applying a promo code?",
    answer: "Unfortunately, redeeming points cannot be combined with additional discount codes."
  }
];

const TermsAndConditions = () => {
    return (
        <main>
            <div className="terms-conditions-container">
              <div className="terms-title">Rewards Terms & Conditions</div>
              {termsData.map((term, index) => (
                  <Accordion key={index} className="terms-accordion" 
                  sx={{
                      boxShadow: 'none',
                      border: 'none',
                      '&:before': { display: 'none' },
                      '&:not(:last-child)': { marginBottom: '10px' }, 
                      '&.Mui-expanded': { marginBottom: '10px' }
                  }}
                  >

                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                      {term.question}
                  </AccordionSummary>
                  <AccordionDetails>
                      {term.answer}
                  </AccordionDetails>
                  </Accordion>
              ))}
            </div>
        <Footer/>
        </main>
      );
    };

export default TermsAndConditions;
