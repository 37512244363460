import React from 'react'

export default function ProgressBar({ percentage,color }) {
    const fillColor = `linear-gradient(to right, #ECE095 ${percentage}%, #F3EFEF ${percentage}%)`;
    const progressBarStyle = {
        height: '8px',
        width: '100%', // Adjust the width based on your design
        background: color,
        borderRadius: '10px',
      };
  return (
    <div style={progressBarStyle}></div>
  )
}
