import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageSwiperAcc from "./imageSwiperAcc";
import { useTranslation } from "react-i18next";

export default function MyAccordion({ product, ProductColor }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="acc-container">
      {/* DETAILS Accordion */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: ProductColor,
                fontSize: "40px",
                strokeWidth: "1px",
              }}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("DETAILS")}
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="single-product-details"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ar"
                  ? product.details_ar
                  : product.details_en,
            }}
          ></div>
        </AccordionDetails>
      </Accordion>

      {/* HOW TO USE Accordion */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: ProductColor, fontSize: "40px" }} />
          }
          aria-controls="panel2-content"
          id="panel2-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("HOW TO USE")}
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="single-product-details"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ar"
                  ? product.how_to_use_ar
                  : product.how_to_use_en,
            }}
          ></div>
        </AccordionDetails>
      </Accordion>

      {/* WHY WE LOVE IT Accordion */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: ProductColor, fontSize: "40px" }} />
          }
          aria-controls="panel3-content"
          id="panel3-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("WHY WE LOVE IT")}
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="single-product-details"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ar"
                  ? product.why_we_love_it_ar
                  : product.why_we_love_it_en,
            }}
          ></div>
        </AccordionDetails>
      </Accordion>

      {/* RECOMMENDED FOR Accordion */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: ProductColor, fontSize: "40px" }} />
          }
          aria-controls="panel4-content"
          id="panel4-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("RECOMMENDED FOR")}
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="single-product-details"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ar"
                  ? product.recommended_for_ar
                  : product.recommended_for_en,
            }}
          ></div>
        </AccordionDetails>
      </Accordion>

      {/* INGREDIENTS Accordion */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: ProductColor, fontSize: "40px" }} />
          }
          aria-controls="panel5-content"
          id="panel5-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("INGREDIENTS")}
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="single-product-details"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ar"
                  ? product.ingredients_ar
                  : product.ingredients_en,
            }}
          ></div>
        </AccordionDetails>
      </Accordion>
      {/* Faqs Accordion */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: ProductColor, fontSize: "40px" }} />
          }
          aria-controls="panel5-content"
          id="panel5-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("FAQs")}
        </AccordionSummary>
        <AccordionDetails>
          <div className="single-product-faq-container">
            {product.faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div className="single-product-faq-question">
                  {faq[`question_${i18n.language.slice(-2)}`]}{" "}
                </div>
                <div className="single-product-faq-answer">
                  {/* Check if the answer contains the URL */}
                  {faq.answer_en.includes(
                    "https://drive.google.com/file/d/1jPlfSpxgXK0-8KbVYSxVVTjfWubfAuIh/view?usp=drive_link"
                  ) ? (
                    <a
                      href="https://drive.google.com/file/d/1jPlfSpxgXK0-8KbVYSxVVTjfWubfAuIh/view?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      {t("Certificate of proof")}
                    </a>
                  ) : (
                    faq[`answer_${i18n.language.slice(-2)}`]
                  )}
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>

      {/* HERO NATURAL INGREDIENTS Accordion */}
      {/* <Accordion style={{ boxShadow: 'none' }} defaultExpanded> */}
      <Accordion style={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: ProductColor, fontSize: "40px" }} />
          }
          aria-controls="panel5-content"
          id="panel5-header"
          className="single-product-title"
          style={{ color: ProductColor }}
        >
          {t("HERO NATURAL INGREDIENTS")}
        </AccordionSummary>
        <AccordionDetails>
          <ImageSwiperAcc images={product.medias} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
