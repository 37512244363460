import React from 'react'
import "../../styles/policies.css"
import Footer from 'components/Footer';
import { useTranslation } from "react-i18next";


export default function Policies () {
    const { t } = useTranslation();
    

    return (
        <main>
            <div className="policies-web">
                <div className="policies-container">
                    <div className="policies-title">{t("Policies")}</div>

                    <span style={{marginBottom:"20px"}}>{t("PoliciesWelcomeToNatinda")}</span> 
                
                    <span style={{fontWeight:500,marginTop:"40px",fontSize:"28px"}}>{t("PoliciesDelivery")}</span>
                    <div>
                    {t("PoliciesDeliveryInfo")}
                    </div>

                    <span style={{fontWeight:500,marginTop:"40px",fontSize:"28px"}}>{t("PoliciesPayment")}</span>
                    <div>
                    {t("PoliciesPaymentInfo")}
                    </div>

                    <span style={{fontWeight:500,marginTop:"40px",fontSize:"28px"}}>{t("PoliciesReturnsAndExchanges")}</span>

                    <div>
                    {t("PoliciesReturnsAndExchangesInfo1")}
                    <br/>
                    {t("PoliciesReturnsAndExchangesInfo2")}<br/>
                    {t("PoliciesReturnsAndExchangesInfo3")}

                    </div>

                    
                </div>
            </div>
            <div className="policies-mob">
                <div className="policies-container-mob">
                    <div className="policies-title">{t("Policies")}</div>
                    <span style={{marginBottom:"20px"}}>{t("PoliciesWelcomeToNatinda")}</span> 
                    <span style={{fontWeight:500,fontSize:"24px"}}>{t("PoliciesDelivery")}</span>
                    <div>
                    {t("PoliciesDeliveryInfo")}
                     </div>

                    <span style={{fontWeight:500,marginTop:"20px",fontSize:"24px"}}>{t("PoliciesPayment")}</span>
                    <div>
                    {t("PoliciesPaymentInfo")}
                    </div>

                    <span style={{fontWeight:500,marginTop:"20px",fontSize:"24px"}}>{t("PoliciesReturnsAndExchanges")}</span>

                    <div>
                    {t("PoliciesReturnsAndExchangesInfo1")}
                    <br/>
                    {t("PoliciesReturnsAndExchangesInfo2")}<br/>
                    {t("PoliciesReturnsAndExchangesInfo3")}
                    </div>
                </div>
            </div>
        <Footer/>
        </main>
      );
}

