import React from "react";
import { useTranslation } from "react-i18next";

// Import Swiper components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function MobileImageSwiper({
  images,
  isHeroNaturalIngredients,
  saleFlag,
  productTheme,
}) {
  const { t } = useTranslation();

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      pagination={true}
      modules={[Pagination]}
      style={{ borderRadius: isHeroNaturalIngredients ? "0" : "10px" }}
    >
      {images.map((media, index) => (
        <SwiperSlide key={index}>
          {saleFlag && (
            <div
              className="sale-badge"
              style={{ backgroundColor: productTheme }}
            >
              {t("Sale")}
            </div>
          )}

          <img
            src={media.image_url}
            alt={`Image ${index + 1}`}
            style={{ width: "100%", height: "auto", maxHeight: "400px" }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
